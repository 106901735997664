const simpleStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "Northern California (Bay Area)",
  "Northern California (Sacramento Region)",
  "Northern California (Other)",
  "Central California",
  "Southern California (LA Region)",
  "Southern California (Orange County)",
  "Southern California (San Diego Region)",
  "Southern California (Other)",
  "Colorado",
  "Florida",
  "Georgia",
  "Idaho",
  "Illinois",
  "Indiana",
  "Kentucky",
  "Kansas",
  "Iowa",
  "Minnesota",
  "Nebraska",
  "Nevada",
  "New Mexico",
  "Ohio",
  "South Carolina",
  "Tennessee",
  "Texas",
  "Wisconsin",
  "Washington",
];

const mysteriousStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "Northern California (Bay Area)",
  "Northern California (Sacramento Region)",
  "Northern California (Other)",
  "Central California",
  "Southern California (LA Region)",
  "Southern California (Orange County)",
  "Southern California (San Diego Region)",
  "Southern California (Other)",
  "Colorado",
  "Idaho",
  "Kentucky",
  "Iowa",
  "Nebraska",
  "Nevada",
  "New Mexico",
  "Ohio",
  "South Carolina",
  "Texas",
];

export { simpleStates, mysteriousStates };
