import React from "react";
import { Col, Row } from "antd";
import "./style.css";
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";

const CustomFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer style={{ background: "#1B1C1F" }}>
      <div style={{ maxWidth: "1300px", margin: "0 auto" }}>
        <Row className="footerContainer">
          <Col xl={2} md={2} xs={24}></Col>
          <Col xl={8} md={8} xs={24}>
            <div className="aboutSection_custom">
              <div className="footerLogoContainer">
                <img
                  src="https://res.cloudinary.com/dgjordf6e/image/upload/v1665470852/image_26_gx8xky_wupe8c.png"
                  alt="chex"
                  style={{ height: "50px", width: "150px" }}
                />
              </div>
              <p className="footerAboutDescription">
                Chex.<span style={{ color: "#FF7A00" }}>AI </span> is a licensed
                vehicle inspection solution and damage detection using AI model.
                Our automated platform simplifies and expedites the process for
                driver acquisition.
              </p>
            </div>

            <div className="iconsContainer">
              <a
                href="https://www.linkedin.com/company/chex-ai/"
                target="_blank"
              >
                <FaLinkedin
                  size={26}
                  color="rgba(98, 102, 112, 1)"
                  style={{ marginRight: 10, marginTop: 0 }}
                />
              </a>
              <a href="/">
                <FaFacebook
                  size={26}
                  color="rgba(98, 102, 112, 1)"
                  style={{ marginRight: 10, marginTop: 0 }}
                />
              </a>
              <a href="/">
                <FaTwitter
                  size={26}
                  color="rgba(98, 102, 112, 1)"
                  style={{ marginRight: 10, marginTop: 0 }}
                />
              </a>
            </div>
          </Col>
          <Col xl={7} md={7} xs={24}>
            <div className="menuContainer">
              <h6 className="headingFooter">Menu</h6>

              <ul className="footerMenuList">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/ride-share">Rideshare Inspections</a>
                </li>
                {/* <li>
                                <a href="/micro-mobility">
                                   Micromobility
                                </a>
                            </li> */}
                <li>
                  <a href="/fleet-inspection">Fleet & DSP Inspections</a>
                </li>
                <li>
                  <a href="/contactPage">Contact us</a>
                </li>
                <li>
                  <a href="/blogPage">Blog</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col xl={4} md={4} xs={24}>
            <div className="ContactContainer">
              <h6 className="headingFooter">Contact Us</h6>
              <div>
                {/* <h6 className="textFooter">
                <a href="tel:(415) 234-3496" className="ankr_phone_footer">
                  <AiOutlinePhone
                    size={24}
                    className="icon_clr_footr"
                    style={{ marginRight: 10, marginTop: 0 }}
                  />
                  (415) 234-3496
                </a>
              </h6> */}
                <h6 className="textFooter">
                  <a
                    href="mailto:support@chex.ai"
                    className="ankr_phone_footer"
                  >
                    {/* <AiOutlineMail
                    size={24}
                    style={{ marginRight: 10, marginTop: 0 }}
                  /> */}
                    support@chex.ai
                  </a>
                </h6>
              </div>

              <div className="footerLinks_custom">
                <span>
                  <Link
                    to="/termsAndPolicy?home=true"
                    style={{ color: "white" }}
                  >
                    Terms of use
                  </Link>
                </span>
                <span>
                  <Link
                    to="/termsAndPolicy?home=true"
                    style={{ color: "white" }}
                  >
                    Privacy Policy
                  </Link>
                </span>

                {/*</div>*/}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col xl={24} md={24} xs={24}>
          <div className="copyrightText">
            <p
              style={{
                color: "#fff",
                fontWeight: "bold",
                fontFamily: "poppins",
              }}
            >
              © {currentYear} CHEX.AI All Rights Reserved
            </p>
          </div>
        </Col>
      </Row>
    </footer>
  );
};

export default CustomFooter;
