import React from "react";
import "./style.css";
import LandingBannerComponent from "../../Components/LandingBannerComponent";
import {
  AboutChexComponent,
  BackedCompanySection,
  CommunityBannerComponent,
  CustomFooter,
  CustomReviewsComponent,
  HelpBusinessSectionComponent,
  HomeBadgeBoxComponent,
  PricingCardRide,
  WhyChexAiComponent,
  RegistrationFormComponent,
} from "../../Components";
import InspectionProcess from "../ChexNewLandingPage/sections/RegisterRideShareSection";
import PricingRideShareSection from "../ChexNewLandingPage/sections/PricingRideShareSection";
import FaqRideShare from "../../Components/RideShareFaqComponent/FaqRideShare";
import HelpBusinessComponent from "../../Components/HelpBusinessComponent/HelpBusinessComponent";

const RideshareScreen = ({
  handleSubmit,
  handleReDirect,
  handleCheckBox,
  handleLoginRedirect,
  handleTermsOfUse,
  isLoading,
  breakPoints,
  carouselRef,
  aboutBreakPoints,
  changeHanlder,
}) => {
  return (
    <div>
      <LandingBannerComponent
        HomeLandingText={
          <div>
            {" "}
            <h3>
              Vehicle <span className="darkBlue"> INSPECTION </span> PLATFORM{" "}
            </h3>
          </div>
        }
        BannerImage={
          "https://res.cloudinary.com/dgjordf6e/image/upload/v1665470838/Group_470_y8tzo1_umxqrk.png"
        }
        BannerTextPara={
          "Our solution is designed for convenience. Complete inspection requirement online through your phone"
        }
        redirectionPage={"toSignup"}
      />

      <div className="box_badge_wrapper_outer">
        <div className="">
          <InspectionProcess />
        </div>
      </div>

      <div>
        <PricingRideShareSection />
      </div>

      {/* Using the new RegistrationFormComponent */}
      <div id="signup">
        <RegistrationFormComponent
          handleSubmit={handleSubmit}
          handleCheckBox={handleCheckBox}
          handleLoginRedirect={handleLoginRedirect}
          handleTermsOfUse={handleTermsOfUse}
          isLoading={isLoading}
        />
      </div>

      <div>
        <BackedCompanySection
          aboutBreakPoints={aboutBreakPoints}
          carouselRef={carouselRef}
          changeHanlder={changeHanlder}
        />
      </div>

      <h1
        style={{
          textAlign: "center",
          color: "black",

          fontWeight: "600",
          marginTop: "4rem",
          fontSize: "40px",
        }}
      >
        Benefits we Propose
      </h1>

      <div className="">
        <div
          style={{
            maxWidth: "1300px",
            margin: "0 auto",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            gap: "50px",
            marginBottom: "40px",
          }}
        >
          <HomeBadgeBoxComponent
            badge_image={
              "https://res.cloudinary.com/dgjordf6e/image/upload/v1741690512/image_13_Traced_bkt832.png"
            }
            badgeHeading={"Easy & Convenient"}
            badgeInfo={
              "Chex.AI's mobile web app is designed for you to conduct inspections at your convenience"
            }
          />

          <HomeBadgeBoxComponent
            badge_image={
              "https://res.cloudinary.com/dgjordf6e/image/upload/v1741690512/image_14_Traced_msts5j.png"
            }
            badgeHeading={"Advanced & Efficient"}
            badgeInfo={
              "Our system uses machine learning technology which gives you results instantly"
            }
          />

          <HomeBadgeBoxComponent
            badge_image={
              "https://res.cloudinary.com/dgjordf6e/image/upload/v1741690512/image_15_Traced_axnsuf.png"
            }
            badgeHeading={"Cost Effective"}
            badgeInfo={
              "We provide the best service at a lower cost when compared to competition"
            }
          />
        </div>
      </div>

      <div>
        <CustomReviewsComponent breakPoints={breakPoints} />
      </div>

      <div>
        <FaqRideShare />
      </div>
      <div>
        <CommunityBannerComponent />
      </div>

      <div>
        <HelpBusinessComponent />
      </div>

      <div>
        <AboutChexComponent />
      </div>

      {/* <div>
        <HelpBusinessSectionComponent
          headingSolution={
            <h2>
              How can we help your
              <span className="business_help_span_style"> business?</span>
            </h2>
          }
          paraSolution={
            "We can help your business optimize asset inspection process. Our interactive dashboard allows you to review data in real time and provide feedback to your customers instantaneously!"
          }
          buttonText={"Contact Us"}
          wrapper_background_style={"wrapper_background_style"}
          imageSolution={
            "https://res.cloudinary.com/dgjordf6e/image/upload/v1665470847/Group_448_nkwgnj_uiddnk.png"
          }
        />
      </div>

      <div className="states_section_main_wrapper_outer">
        <div className="image_wrapper_states_cars">
          <img
            src={
              "https://res.cloudinary.com/dgjordf6e/image/upload/v1666092834/Layer_2_ltzyhz_haonq3.png"
            }
            alt=""
          />
        </div>
        <div className="content_wrapper_states_main">
          <div className="content_inner_state_wrapper">
            <h2>
              <span className="orangeSpan">States</span> where{" "}
              <span className="blueSpan"> Chex.Ai</span> works
            </h2>

            <div className="states_content_data">
              <div className="unorder_lists">
                <ul>
                  <li>Arizona</li>
                  <li>Alabama</li>
                  <li>Alaska</li>
                  <li>Arkansas</li>
                  <li>California</li>
                  <li>Florida</li>
                  <li>Georgia</li>
                  <li>Idaho</li>
                  <li>Indiana</li>
                  <li>Iowa</li>
                  <li>Kansas</li>
                </ul>
              </div>

              <div className="unorder_lists">
                <ul>
                  <li>Missouri</li>
                  <li>Mississippi</li>
                  <li>Montana</li>
                  <li>New Mexico</li>
                  <li>North Dakota</li>
                  <li>Oklahoma</li>
                  <li>Oregon</li>
                  <li>South Carolina</li>
                  <li>South Dakota</li>
                  <li>Tennessee</li>
                  <li>Texas</li>
                  <li>Wyoming</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div>
        <CustomFooter />
      </div>
    </div>
  );
};

export default RideshareScreen;
