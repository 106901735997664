import React from 'react';
import styles from './CommunityBanner.module.css';

const CommunityBanner = () => {
  return (
    <div className={styles.community_wrapper_main_outer}>
      <div className={styles.community_wrapper_main_inner}>
        <h2 className={styles.community_wrapper_title}>
        Our Community of Chex.AI is Trending Fast
        </h2>
        <div className={styles.community_wrapper_counter}>
          <div className={styles.counter_box}>
            <h2 className={styles.counter_value}>
              1000+
            </h2>
            <p className={styles.counter_label}>
              Customers
            </p>
          </div>

          <div className={`${styles.counter_box} ${styles.center_counter_box}`}>
            <h2 className={styles.counter_value}>
              5+
            </h2>
            <p className={styles.counter_label}>
              Years Of Experience
            </p>
          </div>

          <div className={styles.counter_box}>
            <h2 className={styles.counter_value}>
              20+
            </h2>
            <p className={styles.counter_label}>
              Companies
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityBanner;