import React, { useEffect, useState, useRef } from "react";
import { CustomNavbar } from "../index";
import "./style.css";
import { Link } from "react-router-dom";
import { Col } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";

const LandingBannerComponent = ({
  redirectionPage,
  HomeLandingText,
  RideshareText,
  BannerImage,
  BannerTextPara,
  image_banner_class,
  firstCardRef,
  handleScroll,
}) => {
  const [inspectionCount, setInspectionCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const targetCountRef = useRef(0);
  const animationRef = useRef(null);
  const baseUrl = process.env.REACT_APP_DEVELOPMENT_URL; // Get base URL from env or use default
  const history = useHistory();
  const Route = window.location.pathname;

  useEffect(() => {
    const fetchInspectionCount = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          Route === "/ride-share"
            ? `${baseUrl}/inspection/count`
            : `https://n3hqyu9j2i.execute-api.us-east-1.amazonaws.com/chexai-dsp/api/v1/dsp/inspection/count`
        );
        console.log("response", response);
        if (response.data && response.data.count) {
          targetCountRef.current = response.data.count;
          startCountAnimation();
        }
      } catch (error) {
        console.error("Error fetching inspection count:", error);
        console.log(error);
        // Use a fallback count for animation in case of error
        targetCountRef.current = 123321;
        startCountAnimation();
      }
    };

    fetchInspectionCount();

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  // Animation function to increment counter
  const startCountAnimation = () => {
    const duration = 2000; // Animation duration in ms
    const startTime = performance.now();
    const startValue = 0;
    const targetValue = targetCountRef.current;

    const updateCount = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);

      // Easing function for smoother animation
      const easeOutQuad = (t) => t * (2 - t);
      const easedProgress = easeOutQuad(progress);

      const currentCount = Math.floor(
        startValue + (targetValue - startValue) * easedProgress
      );
      setInspectionCount(currentCount);

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(updateCount);
      } else {
        setInspectionCount(targetValue);
        setIsLoading(false);
      }
    };

    animationRef.current = requestAnimationFrame(updateCount);
  };

  // Define background images for different routes
  const backgroundImage = {
    "/": "url('https://res.cloudinary.com/dgjordf6e/image/upload/v1726233443/main-section-background_q4gnqh.png')",
    "/ride-share":
      "url('https://res.cloudinary.com/dgjordf6e/image/upload/v1741600459/rideshare-header-bg_byerup.png')",
    "/uber-inspection":
      "url('https://res.cloudinary.com/dgjordf6e/image/upload/v1726744537/midsection-man-standing-car_1_ixwdx2.png')",
    "/fleet-inspection":
      "url('https://res.cloudinary.com/dgjordf6e/image/upload/v1726233443/main-section-background_q4gnqh.png')",
  };

  // This is the home page
  const HomeHeaderText = "vehicle safety inspection & damage detection";
  const HomeHeaderSpanText = "aI powered";
  const HomeHeaderParagraph =
    "CHEX.AI's Car Damage Recognition is an advanced AI solution that instantly detects dents, scratches, and body defects in vehicles using smartphone photos.";
  const HomeHeaderRoute = "/getStarted";
  const HomeHeaderImage =
    "https://res.cloudinary.com/dgjordf6e/image/upload/v1726236730/chex-landing-page-header-image_dxct5w.png";

  // This is the rideshare page
  const RideShareHeaderSpanText = "RIDESHARE";
  const RideShareHeaderText = "VEHICLE INSPECTION SERVICE";
  const RideShareHeaderParagraph =
    "Our solution is designed for convenience.\nComplete inspection requirement online \nthrough your phone";
  const RideShareHeaderRoute = "/getStarted";
  const RideShareHeaderImage =
    "https://res.cloudinary.com/dgjordf6e/image/upload/v1741600458/rideshare-header_gduyio.png";

  // This is the Fleet&DSP page
  const DSPHeaderText = "Vehicle Damage Detection And Inspection Platform.";
  const DSPHeaderSpanText = "Fleet & DSP";
  const DSPHeaderParagraph = "";
  const DSPHeaderRoute = "/contactPage";
  const DSPHeaderImage =
    "https://res.cloudinary.com/dgjordf6e/image/upload/v1726744738/Group_3465212_a94330.png";

  return (
    <div className="landing_banner_main_wrapper">
      <div
        className="landing_main_section_container"
        style={{ backgroundImage: backgroundImage[Route] }}
      >
        <CustomNavbar scrollValue={30} />
        <div className="landing_main_section_content">
          <div className="landing_main_section_inner">
            <div className="landing_main_section_text">
              {(Route === "/lyft-inspection" ||
                Route === "/uber-inspection") && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",

                    gap: "20px",
                    marginBottom: "30px",
                  }}
                >
                  <img
                    src="https://res.cloudinary.com/dgjordf6e/image/upload/v1742279339/image_82_hhtfhz.png"
                    alt="inspection"
                    className="customeImage"
                  />
                  <img
                    src="https://res.cloudinary.com/dgjordf6e/image/upload/v1742279338/image_81_hij7nn.png"
                    alt="inspection"
                    className="customeImage"
                  />
                  <img
                    src="https://res.cloudinary.com/dgjordf6e/image/upload/v1742279338/turo_1_1_j3vziq.png"
                    alt="inspection"
                    className="customeImage"
                  />
                </div>
              )}
              <h1 className="landing_main_section-heading">
                <span className="highlight">
                  {Route === "/"
                    ? HomeHeaderSpanText
                    : Route === "/ride-share" || Route === "/uber-inspection"
                    ? RideShareHeaderSpanText
                    : DSPHeaderSpanText}
                </span>{" "}
                {Route === "/"
                  ? HomeHeaderText
                  : Route === "/ride-share" || Route === "/uber-inspection"
                  ? RideShareHeaderText
                  : DSPHeaderText}
              </h1>
              <p className="landing_main_section_description">
                {Route === "/"
                  ? HomeHeaderParagraph
                  : Route === "/ride-share" || Route === "/uber-inspection"
                  ? RideShareHeaderParagraph.split("\n").map((text, i) => (
                      <React.Fragment key={i}>
                        {text}
                        {i <
                          RideShareHeaderParagraph.split("\n").length - 1 && (
                          <br />
                        )}
                      </React.Fragment>
                    ))
                  : DSPHeaderParagraph}
              </p>
              <div className="btn_landing_wrapper">
                {Route === "/fleet-inspection" ? (
                  <div className="buttons-container">
                    <button
                      className="demo-button"
                      onClick={() => history.push("/contactPage")}
                    >
                      Get Started
                    </button>
                    {/* {Route !== "/ride-share" &&
                      Route !== "/uber-inspection" && (
                        <button className="demo-button">Request a Demo</button>
                      )} */}
                  </div>
                ) : (
                  <div className="buttons-container">
                    <a
                      className="btn_landing"
                      href={
                        (Route == "/ride-share" && "#signup") ||
                        (Route == "/" && "/ride-share#signup")
                      }
                    >
                      Register your Inspection Today
                    </a>

                    {Route !== "/ride-share" &&
                      Route !== "/uber-inspection" && (
                        <button
                          onClick={() => history.push("/request-a-demo")}
                          className="demo-button"
                        >
                          Request a Demo
                        </button>
                      )}
                  </div>
                )}
              </div>
              <div className="number-container-wrapper">
                {Route === "/ride-share" || Route === "/fleet-inspection" ? (
                  <div className="number-container">
                    <div>
                      <img
                        src="https://res.cloudinary.com/dgjordf6e/image/upload/v1741604279/Vector_ry9hhy.png"
                        alt="Counter icon"
                      />
                    </div>
                    <div
                      className={`number-text ${
                        isLoading ? "number-text-animating" : ""
                      }`}
                    >
                      {inspectionCount.toLocaleString()}
                    </div>
                  </div>
                ) : null}

                {Route === "/ride-share" || Route === "/fleet-inspection" ? (
                  <div className="number-text-para">
                    <span style={{ fontWeight: "bold" }}>
                      {Route === "/ride-share" ? "Rideshare" : "DSP"}
                    </span>{" "}
                    drivers who have successfully completed their vehicle
                    inspection with us!
                  </div>
                ) : null}
              </div>
            </div>
            <div className="landing_main_section_image">
              <img
                src={
                  Route === "/"
                    ? HomeHeaderImage
                    : Route === "/ride-share" || Route === "/uber-inspection"
                    ? RideShareHeaderImage
                    : DSPHeaderImage
                }
                alt="Placeholder"
                className="landing_image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingBannerComponent;
