import React, { useState } from "react";
import "./InspectionProcess.css";

import { useHistory } from "react-router-dom";

const InspectionProcess = ({ steps = defaultSteps }) => {
  const history = useHistory();

  const [stepImage, setStepImage] = useState(
    "https://res.cloudinary.com/dgjordf6e/image/upload/v1741678085/Group_424_hurqzv.png"
  );

  return (
    <div className="inspection-process">
      <div className="inspection-layout">
        <div className="inspection-image-section">
          <img src={stepImage} alt="Inspection Process" />
        </div>

        <div className="inspection-content-section">
          <span className="ride-share-title">ride share</span>
          <h1 className="main-title">
            All You Need to Know About the Chex.AI Inspection Process
          </h1>
          <div className="steps-container">
            <div className="timeline-line"></div>

            {steps.map((step, index) => (
              <div
                key={index}
                className="step-item"
                onMouseEnter={() => setStepImage(step.image)}
              >
                <div className="icon-container-progression">
                  <img
                    src={step.icon}
                    alt={`Step ${index + 1} icon`}
                    className="step-icon"
                  />
                </div>

                <div className="step-content">
                  <span className="step-number">{step.step}</span>
                  <h2 className="step-title">{step.title}</h2>
                  <p className="step-description">{step.description}</p>
                </div>
              </div>
            ))}

            <a className="register-button" href={"#signup"}>
              Register your Inspection Today
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const defaultSteps = [
  {
    step: "Step 1",
    title: "Register",
    description:
      "Please Register your account by filling out the form above to get started",
    icon: "https://res.cloudinary.com/dgjordf6e/image/upload/v1741678085/registered_1_ympyrw.png",
    image:
      "https://res.cloudinary.com/dgjordf6e/image/upload/v1741678085/Group_424_hurqzv.png",
  },
  {
    step: "Step 2",
    title: "Complete Inspection",
    description:
      "Follow the instructions for each inspection point within our app and upload photo and video clips",
    icon: "https://res.cloudinary.com/dgjordf6e/image/upload/v1741678085/consultation_1_d9xhv0.png",
    image:
      "https://res.cloudinary.com/dgjordf6e/image/upload/v1741852203/Group_425_wruwdl.png",
  },
  {
    step: "Step 3",
    title: "Complete Payment",
    description:
      "Enter payment details. If you don't pass, your first re-inspection is free",
    icon: "https://res.cloudinary.com/dgjordf6e/image/upload/v1741678084/certificate_1_1_jblg3b.png",
    image:
      "https://res.cloudinary.com/dgjordf6e/image/upload/v1741928461/Group_429_1_f2i3xb.png",
  },
  {
    step: "Step 4",
    title: "Same-day Certification ",
    description:
      "All certifications completed within 4 hours or less. Downloadable verification in-app",
    icon: "https://res.cloudinary.com/dgjordf6e/image/upload/v1741678084/certificate_1_1_jblg3b.png",
    image:
      "https://res.cloudinary.com/dgjordf6e/image/upload/v1741928461/Group_431_1_wfpb9p.png",
  },
];

export default InspectionProcess;
