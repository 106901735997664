/* eslint-disable */
import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { Select, Modal, Form, Input } from "antd";
import { TiTick } from "react-icons/ti";
import { IoClose } from "react-icons/io5";
import { Header, LogoutModal } from "../../Components";
import {
  MainContainer,
  MainDownContainer,
  ImageBackgroundVehicleInspection,
  ContentFooterareaInspectionInstruction,
  MainBgInsertDetails,
} from "./style.js";
import "./style.css";
import { useHistory, useLocation } from "react-router-dom"; // Add router imports

import CompaniesSection from "./CompaniesSection.js";

import { simpleStates, mysteriousStates } from "./states";

const SelectCompanyScreen = ({
  // companies,
  stateEmpty,
  checkEmpty,
  handleState,
  numberEmpty,
  setStateEmpty,
  loadingcompny,
  submitLoading,
  stateCompanies,
  setSubmitLoading,
  inspectionChecked,
  alreadyExistModal,
  inspectionErrorResp,
  setAlreadyExistModal,
  handleInspectionCheck,
  handleCreateInspection,
  handleLicensePlateNumber,
  handleSubmitAlreadyExist,
  reInspectionPlateNumber,
  handleAutoReInspection,
  reInspectionState,
  inspectionState,
  licensePlateNumber,
  loadingSelect,
  setNumberEmpty,
  setCheckEmpty,
  reInspection,
  handleEditInspection,
  getInspectionDetailData,
  detailLoading,
  handleAddNewCompany,
  selectedCompanies,
  filteredCompaniesSelected,
  filteredCompanies,
  showTuroModal,
  setShowTuroModal,
  handleYearSubmit,
  showErrorMessage,
  closeTuroModal,
  editTuruYear,
  mysterious,
  handleNextStep,
  currentStep,
  vehicleId,
  inspectionId, // Add inspectionId prop
}) => {
  const { Option } = Select;

  const [state] = useState(mysterious ? mysteriousStates : simpleStates);

  // Add history and location hooks for URL manipulation
  const history = useLocation();


  // Helper function to render license plate and state selection
  const renderStepOne = () => {
    return (
      <>
        {/* License Plate Number field for different inspection types */}
        {reInspection === "true" ? (
          <>
            <div className="select-company-heading">License Plate Number </div>
            <div className="license-plate">
              <input
                type="text"
                disabled={true}
                placeholder={reInspectionPlateNumber || "License Plate Number"} // Add fallback
                className="license-plate-text"
                value={reInspectionPlateNumber || ""} // Ensure there's a value
              />
            </div>
          </>
        ) : reInspection === "edit" ? (
          <>
            <div className="select-company-heading">License Plate Number </div>
            <div className="license-plate">
              <input
                type="text"
                disabled={false}
                placeholder="License Plate Number"
                value={licensePlateNumber || ""} // Ensure value is not undefined
                className="license-plate-text"
                onChange={(number) => {
                  handleLicensePlateNumber(number.target.value);
                  setNumberEmpty(false);
                }}
              />
            </div>
          </>
        ) : reInspection === "addCompany" ? (
          <>
            <div className="select-company-heading">License Plate Number </div>
            <div className="license-plate">
              <input
                type="text"
                disabled={true}
                placeholder="License Plate Number"
                value={licensePlateNumber || ""} // Ensure value is not undefined
                className="license-plate-text"
              />
            </div>
          </>
        ) : reInspection === "false" ? (
          <>
            {numberEmpty === true ? (
              <div className="select-company-heading" style={{ color: "red" }}>
                Enter License Plate Number{" "}
              </div>
            ) : (
              <div className="select-company-heading">
                Enter License Plate Number{" "}
              </div>
            )}
            <div className="license-plate">
              <input
                type="text"
                value={licensePlateNumber || ""} // Ensure value is not undefined
                placeholder="License Plate Number"
                className="license-plate-text"
                onChange={(number) => {
                  handleLicensePlateNumber(number.target.value);
                  setNumberEmpty(false);
                }}
              />
            </div>
          </>
        ) : null}

        {/* Inspection State field for different inspection types */}
        {reInspection === "true" ? (
          <>
            {stateEmpty === true ? (
              <div className="select-state-heading" style={{ color: "red" }}>
                Select Inspection State
              </div>
            ) : (
              <div className="select-state-heading">
                Select Inspection State
              </div>
            )}

            <div className="drop-down-container">
              <Select
                className="drop-down"
                labelInValue
                value={inspectionState ? { value: inspectionState } : undefined} // Set value prop for Select component
                placeholder={reInspectionState || "Select State"}
                onChange={(e) => {
                  handleState(e?.value);
                  setStateEmpty(false);
                }}
              >
                {state?.map((state) => (
                  <Option key={state} value={state}>
                    {state}
                  </Option>
                ))}
              </Select>
            </div>

            {/* Add Next button for re-inspection */}
            <div
              className="inspection-btn-container"
              onClick={handleNextStep}
              style={{
                margin: "40px auto",
                width: "200px",
                position: "relative",
              }}
            >
              <div className="inspections-btn-text">Next</div>
            </div>
          </>
        ) : reInspection === "edit" || reInspection === "addCompany" ? (
          <>
            {stateEmpty === true ? (
              <div className="select-state-heading" style={{ color: "red" }}>
                Select Inspection State
              </div>
            ) : (
              <div className="select-state-heading">
                Select Inspection State
              </div>
            )}

            <div className="drop-down-container">
              <Select
                className="drop-down"
                labelInValue
                value={
                  reInspectionState ? { value: reInspectionState } : undefined
                } // Show selected state
                placeholder={reInspectionState || "Select State"}
                disabled={true}
                onChange={(e) => {
                  handleState(e?.value);
                  setStateEmpty(false);
                }}
              >
                {state?.map((state) => (
                  <Option key={state} value={state}>
                    {state}
                  </Option>
                ))}
              </Select>
            </div>

            {/* Add Next button for edit and addCompany routes */}
            <div
              className="inspection-btn-container"
              onClick={handleNextStep}
              style={{
                margin: "40px auto",
                width: "200px",
                position: "relative",
              }}
            >
              <div className="inspections-btn-text">Next</div>
            </div>
          </>
        ) : reInspection === "false" ? (
          <>
            {stateEmpty === true ? (
              <div className="select-state-heading" style={{ color: "red" }}>
                Select Inspection State
              </div>
            ) : (
              <div className="select-state-heading">
                Select Inspection State
              </div>
            )}

            <div className="drop-down-container">
              <Select
                className="drop-down"
                labelInValue
                value={inspectionState ? { value: inspectionState } : undefined} // Show selected state
                placeholder="Select State"
                onChange={(e) => {
                  handleState(e?.value);
                  setStateEmpty(false);
                }}
              >
                {state?.map((state) => (
                  <Option key={state} value={state}>
                    {state}
                  </Option>
                ))}
              </Select>
            </div>

            {/* Update the Next button to scroll with content */}
            <div
              className="inspection-btn-container"
              onClick={handleNextStep}
              style={{
                margin: "40px auto",
                width: "200px",
                position: "relative",
              }}
            >
              <div className="inspections-btn-text">Next</div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  return (
    <MainContainer>
      <ImageBackgroundVehicleInspection>
        <MainBgInsertDetails>
          <div className="select-company-header">
            <div className="select-company-header-content">
              <Header title={true} />
            </div>
          </div>
          <MainDownContainer>
            <ContentFooterareaInspectionInstruction
              className={detailLoading ? "overlayLoader" : ""}
            >
              {detailLoading ? (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "48%",
                    zIndex: 1,
                  }}
                >
                  <ClipLoader color={"#FF7A00"} />
                </div>
              ) : null}

              {reInspection === "true" ? (
                <div className="companyInstructionHeading">Re-Inspection</div>
              ) : (
                <div className="companyInstructionHeading">New Inspection</div>
              )}
              <div>
                <i>
                  {mysterious ? (
                    <div
                      style={{
                        backgroundColor: "#fff5e5",
                        height: "60px",
                        width: "100%",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      // className="checkOutCard"
                    >
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7C4cs3IsHwixvzySaBws043K0ncAgiuMyFbAvyZUdShzDCj-zZWNwxrcJQsNcyyaxnYE&usqp=CAU"
                        alt="mysterious"
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "5px",
                          margin: "5px 5px 5px 10px",
                        }}
                      />
                      <div
                        style={{
                          color: "#ffa51d",
                          fontStyle: "italic",
                          margin: "5px",
                          textAlign: "center",
                        }}
                      >
                        Your inspection is included FREE with your Mystro
                        subscription!
                      </div>
                    </div>
                  ) : (
                    <div className="select-company-inspection-price">
                      <b>$29.99</b> for first inspection and <b>$17.99</b> for
                      each additional selection
                    </div>
                  )}
                </i>

                {/* Conditional rendering based on current step */}
                {currentStep === 1 && renderStepOne()}

                {/* Only render CompaniesSection if we're on step 2 */}
                {currentStep === 2 && (
                  <CompaniesSection
                    checkEmpty={checkEmpty}
                    setCheckEmpty={setCheckEmpty}
                    inspectionState={inspectionState}
                    reInspection={reInspection}
                    stateCompanies={stateCompanies}
                    filteredCompanies={filteredCompanies}
                    filteredCompaniesSelected={filteredCompaniesSelected}
                    handleInspectionCheck={handleInspectionCheck}
                    handleCreateInspection={handleCreateInspection}
                    handleEditInspection={handleEditInspection}
                    handleAddNewCompany={handleAddNewCompany}
                    loadingSelect={loadingSelect}
                    loadingcompny={loadingcompny}
                    inspectionChecked={inspectionChecked}
                    numberEmpty={numberEmpty}
                    setNumberEmpty={setNumberEmpty}
                    stateEmpty={stateEmpty}
                    setStateEmpty={setStateEmpty}
                    licensePlateNumber={licensePlateNumber}
                    TiTick={TiTick}
                    ClipLoader={ClipLoader}
                    handleNextStep={handleNextStep}
                    currentStep={currentStep}
                    inspectionId={inspectionId} // Pass inspectionId to CompaniesSection
                    vehicleId={vehicleId}
                  />
                )}
              </div>

              <LogoutModal
                logout={false}
                loading={submitLoading}
                modal={alreadyExistModal}
                setLoading={setSubmitLoading}
                setModal={setAlreadyExistModal}
                handleDeny={handleAutoReInspection}
                handleSubmit={handleSubmitAlreadyExist}
                errorMessage={inspectionErrorResp?.errorMessage}
              />
              <Modal
                title="Basic Modal"
                visible={showTuroModal}
                centered={true}
              >
                <div className="veh-inspection-verification__formtext ">
                  {/* Turo */}
                </div>
                <div
                  className="inspection--card-close_icon"
                  onClick={() => closeTuroModal()}
                >
                  <IoClose color="#fff" size={19} />
                </div>
                <Form
                  style={{ marginTop: "40px" }}
                  id="myForm"
                  requiredMark={false}
                  layout="vertical"
                  onFinish={handleYearSubmit}
                >
                  <Form.Item
                    name="year"
                    label="Enter Vehicle Year"
                    rules={[
                      { required: true, message: "Please enter a year" },
                      { min: 4, message: "Invalid Year Format" },
                      { max: 4, message: "Invalid Year Format" },
                    ]}
                  >
                    <Input
                      placeholder="Year"
                      disabled={showErrorMessage}
                      required
                    />
                  </Form.Item>
                  <div>
                    {!showErrorMessage ? (
                      <button
                        type="submit"
                        className="vec-inspection-Edit__button"
                      >
                        Submit
                      </button>
                    ) : null}

                    {showErrorMessage ? (
                      <>
                        <div className="turoErrorMsg">
                          <b>Note! </b>
                          Vehicles that are 5 years old or older, Turo requires
                          battery check. Your vehicle does not qualify for a
                          remote inspection for Turo. Please check with
                          your local mechanic.
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <button
                            type="submit"
                            className="turoModalCloseButton"
                            onClick={() => closeTuroModal()}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="turoModalEditButton"
                            onClick={() => editTuruYear()}
                          >
                            Edit
                          </button>
                        </div>
                      </>
                    ) : null}
                  </div>
                </Form>
              </Modal>
            </ContentFooterareaInspectionInstruction>
          </MainDownContainer>
        </MainBgInsertDetails>
      </ImageBackgroundVehicleInspection>
    </MainContainer>
  );
};
export default SelectCompanyScreen;
