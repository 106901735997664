import React, { useRef, useState } from "react";

// import UberVehicleInspectionScreen from "../../Screens/UberVehicleInspectionScreen";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import ActionCreators from "../../actions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// import { RideshareScreen } from "../../Screens";
import { useMediaQuery } from "react-responsive";
import UberAndLyftScreen from "../../Screens/UberAndLyftScreen";

const UberAndLyftPageContainer = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [checkboxValue, setCheckBox] = useState(false);
  const carouselRef = useRef(null);

  const isBigScreen = useMediaQuery({ query: "(min-width: 1108px)" });
  const isTabScreen = useMediaQuery({ query: "(min-width: 575px)" });
  const isMobileScreen = useMediaQuery({ query: "(min-width: 200px)" });

  const changeHanlder = (currentItemObject, currentPageIndex) => {
    if (currentItemObject.index === 2 && isBigScreen) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
    if (currentItemObject.index === 3 && isTabScreen) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }

    if (currentItemObject.index === 4 && isMobileScreen) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };
  const handleCheckBox = () => {
    setCheckBox(!checkboxValue);
  };

  const handleSubmit = (params) => {
    const { register } = props;
    if (params?.phone.includes("_")) {
      addToast("Please enter a valid number", { appearance: "error" });
    } else {
      if (checkboxValue) {
        register(params, history, addToast);
      } else {
        addToast("Please accept term of use", { appearance: "warning" });
      }
    }
  };
  //
  const handleLoginRedirect = () => {
    window.location.href = "/login";
  };
  //
  const handleReDirect = () => {
    window.location.href = "/getStarted";
  };
  //
  const handleTermsOfUse = () => {
    window.location.href = "/termsAndPolicy?uber=true";
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 650, itemsToShow: 1, itemsToScroll: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 2 },
  ];

  const aboutBreakPoints = [
    { width: 1, itemsToShow: 1, showArrows: false },
    { width: 500, itemsToShow: 2, itemsToScroll: 1, showArrows: false },
    { width: 768, itemsToShow: 3, itemsToScroll: 1, showArrows: false },
    { width: 1024, itemsToShow: 4, itemsToScroll: 1, showArrows: false },
    { width: 1200, itemsToShow: 6, itemsToScroll: 1, showArrows: false },
  ];

  return (
    <UberAndLyftScreen
      handleTermsOfUse={handleTermsOfUse}
      handleLoginRedirect={handleLoginRedirect}
      breakPoints={breakPoints}
      handleReDirect={handleReDirect}
      handleSubmit={handleSubmit}
      handleCheckBox={handleCheckBox}
      isLoading={props.isLoading}
      aboutBreakPoints={aboutBreakPoints}
      carouselRef={carouselRef}
      changeHanlder={changeHanlder}
    />
  );
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    isLoading: state.auth.isAuthLoading,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UberAndLyftPageContainer);
