import React from "react";
import styles from "./PricingSection.module.css";
import InspectionCard from "./InpectionCard";

const PricingRideShareSectionServices = ({
  customLogo,
  customLogoHeight,
  customLogoWidth,
}) => {
  return (
    <div className={styles.pricingSection}>
      <h1 className={styles.pricingSectionTitle}>Pricing</h1>
      <p className={styles.pricingSectionPara}>
        Convenience isn't the only benefit of using Chex.AI. We offer best
        pricing for all <br />
        the Inspections along with best in class customer support
      </p>

      <div className={styles.cardsContainer}>
        <InspectionCard
          title="INSPECTION"
          price="29.99"
          description="Single Inspection"
          buttonText="Apply for Inspection"
          backgroundColor="#f5f5f5"
          priceColor="#1976d2"
          buttonColor="#1976d2"
          buttonHoverColor="#1565c0"
          buttonlink={"#signup"}
          customeLogo={
            <img
              src="https://res.cloudinary.com/dgjordf6e/image/upload/v1742279429/image_23_chqsaf.png"
              alt="inspection"
              width={125}
              height={45}
            />
          }
          padding={true}
        />

        <InspectionCard
          title="INSPECTION"
          price="29.99"
          description="Single Inspection"
          buttonText="Apply for Inspection"
          backgroundColor="#f5f5f5"
          priceColor="#1976d2"
          buttonColor="#1976d2"
          buttonHoverColor="#1565c0"
          buttonlink={"#signup"}
          customeLogo={
            <img
              // src="https://res.cloudinary.com/dgjordf6e/image/upload/v1742279338/image_22_kh5uqw.png"
              src={customLogo}
              alt="inspection"
              width={customLogoWidth || 100}
              height={customLogoHeight || 61}
              // width={100}
              // height={61}
            />
          }
        />

        <InspectionCard
          title="PLUS INSPECTION"
          price="47.98"
          description="Bundle Inspection"
          // subDescription="Any two companies for the same inspection"
          buttonText="Apply for Inspection"
          backgroundColor="#f5f5f5"
          priceColor="#F68B1F"
          buttonColor="#F68B1F"
          buttonHoverColor="#E67E0D"
          buttonlink={"#signup"}
          customeLogo={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                height: "61px",
              }}
            >
              <img
                src="https://res.cloudinary.com/dgjordf6e/image/upload/v1742279429/image_23_chqsaf.png"
                alt="inspection"
                width={125}
                height={45}
              />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#ffffff",
                  background: "#1468BA",
                  padding: "0 8px",
                  borderRadius: "50%",
                }}
              >
                +
              </div>
              <img
                // src="https://res.cloudinary.com/dgjordf6e/image/upload/v1742279338/image_22_kh5uqw.png"
                src={customLogo}
                alt="inspection"
                width={customLogoWidth || 100}
                height={customLogoHeight || 61}
              />
            </div>
          }
        />
      </div>

      <div className={styles.container}>
        <div className={styles.row}>
          <div style={{ display: "grid", gap: "15px" }}>
            <div className={styles.item}>
              <img
                src="https://res.cloudinary.com/dgjordf6e/image/upload/v1741690512/Group_1000004770_d3sqac.png"
                alt="inspection"
                className={styles.icon}
              />
              <span className={styles.text}>
                Inspection results within 4 hours
              </span>
            </div>

            <div className={styles.item}>
              <img
                src="https://res.cloudinary.com/dgjordf6e/image/upload/v1741690512/Group_1000004770_d3sqac.png"
                alt="price"
                className={styles.icon}
              />
              <span className={styles.text}>
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "#F68B1F",
                  }}
                >
                  $17.99
                </span>{" "}
                for additional company
              </span>
            </div>
          </div>

          <div style={{ display: "grid", gap: "15px" }}>
            <div className={styles.item}>
              <img
                src="https://res.cloudinary.com/dgjordf6e/image/upload/v1741690512/Group_1000004770_d3sqac.png"
                alt="certification"
                className={styles.icon}
              />
              <span className={styles.text}>Verified certification in-app</span>
            </div>
            <div className={styles.item}>
              <img
                src="https://res.cloudinary.com/dgjordf6e/image/upload/v1741690512/Group_1000004770_d3sqac.png"
                alt="appointments"
                className={styles.icon}
              />
              <span className={styles.text}>No appointments needed</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingRideShareSectionServices;
