/* eslint-disable */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useToasts } from "react-toast-notifications";

import ActionCreators from "../../actions";

import { SelectCompanyScreen } from "../../Screens";
import { BiWindows } from "react-icons/bi";
import {
  getInspectionCompaniesEdit,
  getInspectionDetail,
} from "../../actions/vehicleInspection";
import axios from "axios";
import { Api } from "../../services/configs";
import { useHistory, useLocation } from "react-router-dom";

const SelectCompanyContainer = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();


  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const nextParam = queryParams.get("next") === "true";
  const licensePlateParam = queryParams.get("licensePlate") || "";
  const inspectionStateParam = queryParams.get("inspectionState") || "";
  const inspectionIdParam = queryParams.get("inspectionId") || "";
  const reInspectionPlateParam = queryParams.get("reInspectionPlate") || "";
  // Extract vehicleId from query params
  const vehicleIdParam = queryParams.get("vehicleId") || "";

  // Get selected companies from URL if they exist
  const selectedCompaniesParam = queryParams.get("selectedCompanies") || "";
  let parsedSelectedCompanies = [];
  try {
    if (selectedCompaniesParam) {
      parsedSelectedCompanies = JSON.parse(selectedCompaniesParam);
    }
  } catch (e) {
    console.error("Error parsing selectedCompanies from URL:", e);
  }

  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  };

  const {
    createInspection,
    createReInspection,
    getCompaniesByState,
    stateCompanies,
    companies,
    match,
    getInspectionCompaniesEdit,
    getInspectionDetail,
    getNewCompanyData,
  } = props;

  const [inspectionChecked, setInspectionChecked] = useState([]);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [loadingcompny, setLoadingcompny] = useState(false);
  const [checkEmpty, setCheckEmpty] = useState(false);
  const [numberEmpty, setNumberEmpty] = useState(false);
  const [inspectionState, setInspectionState] = useState(inspectionStateParam);
  const [stateEmpty, setStateEmpty] = useState(false);
  const [licensePlateNumber, setLicensePlateNumber] =
    useState(licensePlateParam);
  const [alreadyExistModal, setAlreadyExistModal] = useState(false);
  const [inspectionErrorResp, setInspectionErrorResp] = useState("");
  const [getInspectionDetailData, setGetInspectionDetailData] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [showTuroModal, setShowTuroModal] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [currentStep, setCurrentStep] = useState(nextParam ? 2 : 1); // Add this line to track the current step
  const [reInspectionPlateNumber, setReInspectionPlateNumber] = useState(
    props?.location?.state?.plateNumber || reInspectionPlateParam || ""
  );

  // Extract vehicleId from route params if it exists
  const routeParams = match?.params || {};
  const locationState = props?.location?.state || {};

  // Add state to store vehicleId with proper initialization from all possible sources
  const [vehicleId, setVehicleId] = useState(
    locationState.vehicleId || vehicleIdParam || ""
  );

  const [turoState, setTuroState] = useState("");

  const mysterious =
    JSON.parse(localStorage.getItem("currentUser"))?.isMysterious === true;

  // Add explicit inspectionId state variable
  const [inspectionId, setInspectionId] = useState(
    props?.location?.state?.InspectionId || inspectionIdParam || ""
  );

  // Add a state variable specifically for reInspectionState
  const [reInspectionState, setReInspectionState] = useState(
    props?.location?.state?.state || inspectionStateParam || ""
  );

  useEffect(() => {
    if (
      match?.params?.inspection === "true" &&
      inspectionState === "" &&
      props?.location?.state?.state
    ) {
      const body = {
        stateName: props?.location?.state?.state,
      };
      getCompaniesByState(body, setLoadingcompny);
    }

    if (
      match?.params?.inspection === "edit" &&
      inspectionState === "" &&
      props?.location?.state?.state
    ) {
      const body = {
        stateName: props?.location?.state?.state,
      };
      getCompaniesByState(body, setLoadingcompny);
    }

    if (
      match?.params?.inspection === "addCompany" &&
      inspectionState === "" &&
      props?.location?.state?.state
    ) {
      const body = {
        stateName: props?.location?.state?.state,
      };

      getCompaniesByState(
        body,
        setLoadingcompny,
        match?.params?.inspection,
        props?.location?.state?.selectedCompanies
      );
    }

    if (inspectionState != "") {
      const body = {
        stateName: inspectionState,
      };

      getCompaniesByState(body, setLoadingcompny);
    }

    if (
      match?.params?.inspection === "addCompany" &&
      inspectionState === "" &&
      props?.location?.state?.state
    ) {
    } else {
      let myArray = [];
      props?.location?.state?.selectedCompanies?.map((data) => {
        myArray.push(data);
      });
      setInspectionChecked(myArray);
    }
  }, [inspectionState]);

  const editTuruYear = () => {
    setShowErrorMessage(false);
  };

  const closeTuroModal = () => {
    var arr = inspectionChecked;
    arr = arr.filter((obj) => obj !== 15);
    setInspectionChecked(arr);
    setShowTuroModal(false);
  };

  const handleYearSubmit = (values) => {
    const { year } = values;
    if (year <= 2018) {
      setShowErrorMessage(true);
    } else {
      setShowTuroModal(false);
    }
  };

  const handleInspectionCheck = (id) => {
    if (id === 15 && !inspectionChecked.includes(15)) {
      setShowTuroModal(true);
    }
    const arr = [...inspectionChecked];
    let index = arr.indexOf(id);
    if (index > -1) {
      if (id === 16) {
        handleTuro("Turoadd");
      } else if (id === 15) {
        handleTuro("hopskipAdd");
      }
      arr.splice(index, 1);
      setInspectionChecked([...arr]);
    } else {
      if (id === 16) {
        handleTuro("Turoremove");
      } else if (id === 15) {
        handleTuro("hopskipRemove");
      }
      arr.push(id);
      setInspectionChecked([...arr]);
    }
  };

  const handleTuro = (checkType) => {
    if (
      checkType === "Turoremove" &&
      (inspectionState === "California" ||
        inspectionState === "Northern California (Bay Area)" ||
        inspectionState === "Northern California (Sacramento Region)" ||
        inspectionState === "Northern California (Other)" ||
        inspectionState === "Central California" ||
        inspectionState === "Southern California (LA region)" ||
        inspectionState === "Southern California (Orange County)" ||
        inspectionState === "Southern California (San Diego Region)" ||
        inspectionState === "Southern California (Other)" ||
        props?.location?.state?.state === "California")
    ) {
      setTuroState(stateCompanies.splice(4, 1));
    } else if (
      checkType === "Turoadd" &&
      (inspectionState === "California" ||
        inspectionState === "Northern California (Bay Area)" ||
        inspectionState === "Northern California (Sacramento Region)" ||
        inspectionState === "Northern California (Other)" ||
        inspectionState === "Central California" ||
        inspectionState === "Southern California (LA region)" ||
        inspectionState === "Southern California (Orange County)" ||
        inspectionState === "Southern California (San Diego Region)" ||
        inspectionState === "Southern California (Other)" ||
        props?.location?.state?.state === "California")
    ) {
      if (turoState.length !== 0) {
        stateCompanies.splice(4, 0, turoState[0]);
      }
    } else if (
      checkType === "hopskipAdd" &&
      (inspectionState === "California" ||
        inspectionState === "Northern California (Bay Area)" ||
        inspectionState === "Northern California (Sacramento Region)" ||
        inspectionState === "Northern California (Other)" ||
        inspectionState === "Central California" ||
        inspectionState === "Southern California (LA region)" ||
        inspectionState === "Southern California (Orange County)" ||
        inspectionState === "Southern California (San Diego Region)" ||
        inspectionState === "Southern California (Other)" ||
        props?.location?.state?.state === "California")
    ) {
      if (turoState.length !== 0) {
        stateCompanies.splice(5, 0, turoState[0]);
      }
    } else if (
      checkType === "hopskipRemove" &&
      (inspectionState === "California" ||
        inspectionState === "Northern California (Bay Area)" ||
        inspectionState === "Northern California (Sacramento Region)" ||
        inspectionState === "Northern California (Other)" ||
        inspectionState === "Central California" ||
        inspectionState === "Southern California (LA region)" ||
        inspectionState === "Southern California (Orange County)" ||
        inspectionState === "Southern California (San Diego Region)" ||
        inspectionState === "Southern California (Other)" ||
        props?.location?.state?.state === "California")
    ) {
      setTuroState(stateCompanies.splice(5, 1));
    }
  };

  if (match?.params?.inspection === "edit") {
    useEffect(() => {
      const { match } = props;

      // axios
      //     .get(`${process.env.REACT_APP_DEVELOPMENT_URL}/vehicle/detail/${props?.location?.state?.InspectionId}`, { headers })
      //     .then((resp) => {
      //
      //           setGetInspectionDetailData(resp.data.Vehicle.licensePlateNumber);
      //
      //         }
      //     )
      //     .catch((err) => {
      //
      //     });
      getInspectionDetail(
        props?.location?.state?.InspectionId,
        setGetInspectionDetailData,
        setDetailLoading,
        setLicensePlateNumber
      );
    }, []);
  } else if (match?.params?.inspection === "addCompany") {
    useEffect(() => {
      const { match } = props;
      getInspectionDetail(
        props?.location?.state?.InspectionId,
        setGetInspectionDetailData,
        setDetailLoading,
        setLicensePlateNumber
      );
    }, []);
  }

  // const inspectionDataEdit = {
  //
  //   plateNumber:getInspectionDetailData?.Vehicle?.licensePlateNumber,
  //
  // }
  const handleSubmitAlreadyExist = () => {
    window.location.href = `/vehicleinspection/${inspectionErrorResp?.inspectionId}/${inspectionErrorResp?.vehicleId}?lyftUser=${inspectionErrorResp?.lyftInspection}`;
  };

  const handleAutoReInspection = (e) => {
    setAlreadyExistModal(false);
    setLicensePlateNumber("");
    setNumberEmpty(true);
  };

  const handleLicensePlateNumber = (number) => {
    setLicensePlateNumber(number);

    // Update URL with the new license plate but maintain other params
    const params = new URLSearchParams(location.search);
    params.set("licensePlate", number);

    // Replace current URL without adding to history stack
    history.replace({
      pathname: location.pathname,
      search: params.toString(),
      state: history.location.state || {},
    });
  };

  const handleState = (state) => {
    setInspectionState(state);

    // Update URL with the new state but maintain other params
    const params = new URLSearchParams(location.search);
    params.set("inspectionState", state);

    // Replace current URL without adding to history stack
    history.replace({
      pathname: location.pathname,
      search: params.toString(),
      state: history.location.state || {},
    });
  };

  const handlEmptyFields = () => {
    inspectionChecked.length === 0 ? setCheckEmpty(true) : setCheckEmpty(false);
  };

  const handleCreateInspection = (reInspection) => {
    if (reInspection === "false") {
      const body = {
        licensePlateNumber,
        inspectionState,
        companies: inspectionChecked,
      };
      createInspection(
        body,
        setLoadingSelect,
        addToast,
        setAlreadyExistModal,
        setInspectionErrorResp,
        setLicensePlateNumber,
        setNumberEmpty
      );
    } else if (reInspection === "true") {
      // Get vehicleId from state, props, or URL
      const vehicleIdToUse =
        vehicleId || props?.location?.state?.vehicleId || "";

 
      // Add validation to prevent undefined
      if (!vehicleIdToUse) {
        addToast("Vehicle ID not found. Please try again.", {
          appearance: "error",
          autoDismiss: true,
        });
        return;
      }

      const body = {
        inspectionState: inspectionState
          ? inspectionState
          : props?.location?.state?.state,
        companies: inspectionChecked,
      };

      createReInspection(vehicleIdToUse, body, setLoadingSelect);
    }
  };

  const handleEditInspection = (reInspection) => {
    if (reInspection === "edit") {
      // Get previously selected companies
      // const previouslySelectedCompanies = props?.location?.state?.selectedCompanies || 
      //   parsedSelectedCompanies || 
      //   [];

      // // Filter to only include newly selected companies (not in previous selection)
      // const newlySelectedCompanies = inspectionChecked?.filter(
      //   item => item !== null && !previouslySelectedCompanies.includes(item)
      // );

      let companiesToBeSend = inspectionChecked?.filter(
        (item) => item !== null
      );

      
      const body = {
        companies: companiesToBeSend,
        licensePlateNumber: licensePlateNumber,
      };

      // Get inspectionId from state with fallback options
      const inspectionIdToUse =
        inspectionId ||
        props?.location?.state?.InspectionId ||
        queryParams.get("inspectionId");

      // Add validation to prevent undefined inspectionId
      if (!inspectionIdToUse) {
        addToast("Inspection ID not found. Please try again.", {
          appearance: "error",
          autoDismiss: true,
        });
        return;
      }

      const vehicleId = props?.location?.state?.VehicleId || vehicleIdParam;

      getInspectionCompaniesEdit(
        inspectionIdToUse,
        body,
        setLoadingSelect,
        vehicleId,
        history,
        addToast
      );
    }
  };

  const handleAddNewCompany = (reInspectionData) => {
    if (reInspectionData === "addCompany") {
      // Get previously selected companies
      const previouslySelectedCompanies = props?.location?.state?.selectedCompanies || 
        parsedSelectedCompanies || 
        [];

      // Filter to only include newly selected companies (not in previous selection)
      const newlySelectedCompanies = inspectionChecked?.filter(
        item => item !== null && !previouslySelectedCompanies.includes(item)
      );


      const body = {
        companies: newlySelectedCompanies,
      };

      // Get inspectionId from all possible sources
      const inspectionIdToUse =
        inspectionId ||
        match?.params?.id ||
        props?.location?.state?.InspectionId ||
        queryParams.get("inspectionId");


      if (!inspectionIdToUse) {
        addToast("Inspection ID not found. Please try again.", {
          appearance: "error",
          autoDismiss: true,
        });
        return;
      }

      const vehicleIdToUse =
        vehicleId ||
        props?.location?.state?.vehicleId ||
        props?.location?.state?.VehicleId ||
        queryParams.get("vehicleId");

      getNewCompanyData(
        inspectionIdToUse,
        body,
        setLoadingSelect,
        history,
        vehicleIdToUse,
        addToast
      );
    }
  };

  useEffect(() => {
    if (
      (props?.location?.state?.state === "California" ||
        props?.location?.state?.state === "Northern California (Bay Area)" ||
        props?.location?.state?.state ===
          "Northern California (Sacramento Region)" ||
        props?.location?.state?.state === "Northern California (Other)" ||
        props?.location?.state?.state === "Central California" ||
        props?.location?.state?.state === "Southern California (LA region)" ||
        props?.location?.state?.state ===
          "Southern California (Orange County)" ||
        props?.location?.state?.state ===
          "Southern California (San Diego Region)" ||
        props?.location?.state?.state === "Southern California (Other)") &&
      (inspectionChecked?.includes(16) ||
        props?.location?.state?.selectedCompanies?.includes(16))
    ) {
      setTuroState(stateCompanies?.splice(4, 1));
    } else if (
      (props?.location?.state?.state === "California" ||
        props?.location?.state?.state === "Northern California (Bay Area)" ||
        props?.location?.state?.state ===
          "Northern California (Sacramento Region)" ||
        props?.location?.state?.state === "Northern California (Other)" ||
        props?.location?.state?.state === "Central California" ||
        props?.location?.state?.state === "Southern California (LA region)" ||
        props?.location?.state?.state ===
          "Southern California (Orange County)" ||
        props?.location?.state?.state ===
          "Southern California (San Diego Region)" ||
        props?.location?.state?.state === "Southern California (Other)") &&
      (inspectionChecked?.includes(15) ||
        props?.location?.state?.selectedCompanies?.includes(15))
    ) {
      setTuroState(stateCompanies?.splice(5, 1));
    }
  }, [stateCompanies]);

  const filteredCompanies = () => {
    // Get selected companies from props.location.state or URL parameters
    const selectedCompanies =
      props?.location?.state?.selectedCompanies ||
      parsedSelectedCompanies ||
      [];

    // Check if selectedCompanies is an array and has length
    if (
      Array.isArray(selectedCompanies) &&
      selectedCompanies.length > 0 &&
      stateCompanies
    ) {
      return stateCompanies.filter(
        (company) =>
          !selectedCompanies.includes(company.id) &&
          company.displayOnFront === true
      );
    } else {
      return stateCompanies
        ? stateCompanies.filter((company) => company.displayOnFront === true)
        : [];
    }
  };

  const filteredCompaniesSelected = () => {
    // Get selected companies from props.location.state or URL parameters
    const selectedCompanies =
      props?.location?.state?.selectedCompanies ||
      parsedSelectedCompanies ||
      [];

    // Check if selectedCompanies is an array and has length
    if (
      Array.isArray(selectedCompanies) &&
      selectedCompanies.length > 0 &&
      stateCompanies
    ) {
      return stateCompanies.filter(
        (company) =>
          selectedCompanies.includes(company.id) &&
          company.displayOnFront === true
      );
    } else {
      return [];
    }
  };

  // Add this function to handle Next button click
  const handleNextStep = () => {
    // Validate inputs before moving to next step
    let isValid = true;

    // Different validation based on inspection type
    if (match?.params?.inspection === "true") {
      // For re-inspection
      if (!inspectionState && !props?.location?.state?.state) {
        setStateEmpty(true);
        isValid = false;
      }
    } else if (match?.params?.inspection === "edit") {
      // For edit inspection, license plate is required
      if (!licensePlateNumber) {
        setNumberEmpty(true);
        isValid = false;
      }
    } else if (match?.params?.inspection === "addCompany") {
      // For add company, no additional validation needed
    } else {
      // For new inspection, validate both license plate and state
      if (!licensePlateNumber) {
        setNumberEmpty(true);
        isValid = false;
      }

      if (!inspectionState) {
        setStateEmpty(true);
        isValid = false;
      }
    }

    if (isValid) {
      setCurrentStep(2);

      // Update URL to include all parameters for step 2
      const params = new URLSearchParams();
      params.set("next", "true");
      params.set(
        "licensePlate",
        licensePlateNumber || reInspectionPlateNumber || ""
      );
      params.set(
        "inspectionState",
        inspectionState || props?.location?.state?.state || ""
      );

      // Add vehicleId to params if it exists
      if (
        vehicleId ||
        props?.location?.state?.vehicleId ||
        props?.location?.state?.VehicleId
      ) {
        params.set(
          "vehicleId",
          vehicleId ||
            props?.location?.state?.vehicleId ||
            props?.location?.state?.VehicleId
        );
      }

      // For re-inspection, preserve the reInspection parameter
      if (match?.params?.inspection) {
        params.set("reInspection", match.params.inspection);
      }

      // For edit mode, include the inspectionId
      if (
        match?.params?.inspection === "edit" &&
        props?.location?.state?.InspectionId
      ) {
        params.set("inspectionId", props?.location?.state?.InspectionId);
      }

      // For addCompany route, include inspectionId and selectedCompanies in URL
      if (match?.params?.inspection === "addCompany") {
        const inspectionIdToUse =
          match?.params?.id ||
          props?.location?.state?.InspectionId ||
          inspectionIdParam ||
          inspectionId;

        if (inspectionIdToUse) {
          params.set("inspectionId", inspectionIdToUse);
        }

        // Include selected companies in URL if available
        if (props?.location?.state?.selectedCompanies) {
          params.set(
            "selectedCompanies",
            JSON.stringify(props.location.state.selectedCompanies)
          );
        }
      }

      // Push to history stack so back button works
      history.push({
        pathname: location.pathname,
        search: params.toString(),
        state: history.location.state || {},
      });

      // If we're on a new inspection, re-inspection, or addCompany, fetch companies based on the selected state
      const stateToUse = inspectionState || props?.location?.state?.state;
      if (stateToUse) {
        const body = {
          stateName: stateToUse,
        };

        // For addCompany route, ensure we're passing the selected companies
        if (match?.params?.inspection === "addCompany") {
          getCompaniesByState(
            body,
            setLoadingcompny,
            "addCompany",
            props?.location?.state?.selectedCompanies
          );
        } else {
          getCompaniesByState(body, setLoadingcompny);
        }
      }
    }
  };

  // Check query params on component mount - enhance to handle edit mode
  useEffect(() => {
    // Update step based on URL parameters
    if (nextParam) {
      setCurrentStep(2);

      // If we're on step 2 from URL params, ensure we fetch the companies
      if (inspectionStateParam !== "") {
        const body = {
          stateName: inspectionStateParam,
        };

        getCompaniesByState(body, setLoadingcompny);
      }
    } else {
      setCurrentStep(1);
    }
  }, [location.search, match?.params?.inspection]);

  // Listen for history changes (like back button)
  useEffect(() => {
    const handlePopState = () => {
      const newParams = new URLSearchParams(window.location.search);
      const isNextStep = newParams.get("next") === "true";
      setCurrentStep(isNextStep ? 2 : 1);
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  // Update state values whenever query parameters change
  useEffect(() => {
    // Get updated values from URL
    const queryParams = new URLSearchParams(location.search);
    const licensePlateParamNew = queryParams.get("licensePlate");
    const inspectionStateParamNew = queryParams.get("inspectionState");
    const reInspectionPlateParamNew = queryParams.get("reInspectionPlate");
    const vehicleIdParamNew = queryParams.get("vehicleId");
    const inspectionIdParamNew = queryParams.get("inspectionId");

    // Update state values if parameters exist and are different from current state
    if (licensePlateParamNew && licensePlateParamNew !== licensePlateNumber) {
      setLicensePlateNumber(licensePlateParamNew);
    }

    if (
      inspectionStateParamNew &&
      inspectionStateParamNew !== inspectionState
    ) {
      setInspectionState(inspectionStateParamNew);
      // Also update reInspectionState when inspectionState changes
      setReInspectionState(inspectionStateParamNew);
    }

    if (
      reInspectionPlateParamNew &&
      reInspectionPlateParamNew !== reInspectionPlateNumber
    ) {
      setReInspectionPlateNumber(reInspectionPlateParamNew);
    }

    if (vehicleIdParamNew && vehicleIdParamNew !== vehicleId) {
      setVehicleId(vehicleIdParamNew);
    }

    if (inspectionIdParamNew && inspectionIdParamNew !== inspectionId) {
      setInspectionId(inspectionIdParamNew);
    }
  }, [
    location.search,
    licensePlateNumber,
    inspectionState,
    reInspectionPlateNumber,
    vehicleId,
    inspectionId,
  ]);

  // Initialize vehicleId from props at component mount
  useEffect(() => {
    if (props?.location?.state?.vehicleId) {
      setVehicleId(props.location.state.vehicleId);
    }
  }, [props?.location?.state?.vehicleId]);

  // Additional initialization for edit mode
  useEffect(() => {
    if (match?.params?.inspection === "edit") {
      // Make sure vehicleId is properly initialized for edit mode
      if (props?.location?.state?.VehicleId && !vehicleId) {
        setVehicleId(props.location.state.VehicleId);
      }
    }
  }, [match?.params?.inspection, props?.location?.state, vehicleId]);

  // Initialize inspectionId when component mounts
  useEffect(() => {
    if (match?.params?.inspection === "edit") {
      if (props?.location?.state?.InspectionId) {
        setInspectionId(props.location.state.InspectionId);
      } else if (inspectionIdParam) {
        setInspectionId(inspectionIdParam);
      }
    }
  }, [
    props?.location?.state?.InspectionId,
    inspectionIdParam,
    match?.params?.inspection,
  ]);

  // Add a synchronizer to keep URL params updated when state changes
  useEffect(() => {
    if (currentStep === 1) {
      // Only update URL params if they've actually changed
      const params = new URLSearchParams(location.search);
      let paramsChanged = false;

      // Check and update license plate param
      if (
        licensePlateNumber &&
        params.get("licensePlate") !== licensePlateNumber
      ) {
        params.set("licensePlate", licensePlateNumber);
        paramsChanged = true;
      }

      // Check and update inspection state param
      if (
        inspectionState &&
        params.get("inspectionState") !== inspectionState
      ) {
        params.set("inspectionState", inspectionState);
        paramsChanged = true;
      }

      // Only update URL if something changed
      if (paramsChanged) {
        history.replace({
          pathname: location.pathname,
          search: params.toString(),
          state: history.location.state || {},
        });
      }
    }
  }, [
    licensePlateNumber,
    inspectionState,
    reInspectionPlateNumber,
    currentStep,
    location.search,
    history,
    location.pathname,
  ]);

  // Replace the conditional useEffect blocks for edit and addCompany
  useEffect(() => {
    const mode = match?.params?.inspection;
    if (mode === "edit" || mode === "addCompany") {
      // Get inspectionId from all possible sources
      const inspectionIdToUse =
        props?.location?.state?.InspectionId ||
        match?.params?.id ||
        inspectionIdParam ||
        inspectionId;


      if (inspectionIdToUse) {
        getInspectionDetail(
          inspectionIdToUse,
          (data) => {
            setGetInspectionDetailData(data);
            // Get and set state from API response
            if (data && data.Vehicle && data.Vehicle.state) {
              setReInspectionState(data.Vehicle.state);
            }
            setLicensePlateNumber(data?.Vehicle?.licensePlateNumber || "");
            setDetailLoading(false);
          },
          setDetailLoading,
          setLicensePlateNumber
        );
      } else {
        console.error(`Missing inspectionId for ${mode} mode`);
        addToast("Unable to load inspection details: Missing inspection ID", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  }, [
    match?.params?.inspection,
    props?.location?.state?.InspectionId,
    inspectionIdParam,
    inspectionId,
    match?.params?.id,
  ]);

  return (
    <SelectCompanyScreen
      filteredCompanies={filteredCompanies}
      filteredCompaniesSelected={filteredCompaniesSelected}
      companies={companies}
      stateCompanies={stateCompanies}
      checkEmpty={checkEmpty}
      stateEmpty={stateEmpty}
      numberEmpty={numberEmpty}
      handleState={handleState}
      setStateEmpty={setStateEmpty}
      setCheckEmpty={setCheckEmpty}
      submitLoading={submitLoading}
      loadingSelect={loadingSelect}
      loadingcompny={loadingcompny}
      setNumberEmpty={setNumberEmpty}
      inspectionState={inspectionState}
      setSubmitLoading={setSubmitLoading}
      handlEmptyFields={handlEmptyFields}
      inspectionChecked={inspectionChecked}
      alreadyExistModal={alreadyExistModal}
      licensePlateNumber={licensePlateNumber}
      reInspection={match?.params?.inspection}
      inspectionErrorResp={inspectionErrorResp}
      handleInspectionCheck={handleInspectionCheck}
      handleAutoReInspection={handleAutoReInspection}
      handleCreateInspection={handleCreateInspection}
      reInspectionState={reInspectionState}
      // InspectionEditState={inspectionDataEdit}
      getInspectionDetailData={getInspectionDetailData}
      handleSubmitAlreadyExist={handleSubmitAlreadyExist}
      handleLicensePlateNumber={handleLicensePlateNumber}
      handleEditInspection={handleEditInspection}
      detailLoading={detailLoading}
      reInspectionPlateNumber={reInspectionPlateNumber}
      handleAddNewCompany={handleAddNewCompany}
      selectedCompanies={props?.location?.state?.selectedCompanies}
      setShowTuroModal={setShowTuroModal}
      showTuroModal={showTuroModal}
      handleYearSubmit={handleYearSubmit}
      showErrorMessage={showErrorMessage}
      closeTuroModal={closeTuroModal}
      editTuruYear={editTuruYear}
      mysterious={mysterious}
      handleNextStep={handleNextStep}
      currentStep={currentStep}
      vehicleId={vehicleId}
      inspectionId={inspectionId} // Pass inspectionId to the screen component
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    vehiclesByStatus: state.vehicleInstruction.inspectionByStatus,
    companies: state?.auth?.companies?.data,
    stateCompanies: state?.auth?.stateCompanies?.data,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectCompanyContainer);
