import React from "react";
import styles from "./HelpBusinessComponent.module.css";
import { useHistory } from "react-router-dom";

const HelpBusinessComponent = () => {
  const history = useHistory();
  const title = "How can we help your?";
  const description =
    "We can help your business optimize asset inspection process. Our interactive dashboard allows you to review data in real time and provide feedback to your customers instantaneously!";
  const buttonText = "Contact Us";
  const onContactClick = () => {
    history.push("/contactPage");
  };
  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <h1 className={styles.title}>
          {title} <span style={{ color: "#FF9900" }}>business?</span>
        </h1>
        <p className={styles.description}>{description}</p>
        <button className={styles.contactButton} onClick={onContactClick}>
          <span className={styles.buttonText}>{buttonText}</span>
        </button>
      </div>
      <div className={styles.imageWrapper}>
        <img
          src="https://res.cloudinary.com/dgjordf6e/image/upload/v1741757739/Group_1000004778_h0vzba.png"
          alt="business-help"
          className={styles.image}
        />
      </div>
    </div>
  );
};

export default HelpBusinessComponent;
