import React from "react";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import {
  Button,
  ContactUsNavbar,
  CustomFooter,
  FaqsComponent,
} from "../../Components";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";

import "../../Components/Input/style";
import "./style.css";
import { Col, Row } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import ClipLoader from "react-spinners/ClipLoader";

const Faqs = ({ scrollValue, data }) => {
  return (
    <div className="main_contactWrap">
      <Row>
        <Col xl={24} md={24} xs={24}>
          <ContactUsNavbar scrollValue={scrollValue} />
          <div className="contact_headingContainer">
            <h3 className="contactText contactText_faqs">
              Frequently Asked Questions
            </h3>
          </div>
        </Col>
      </Row>

      <section className="faqsContainerStyle">
        {data?.rows.map((data, index) => {
          return (
            <Row key={index}>
              <Col xl={24} md={24} xs={24}>
                <div className="faqsDivWrapper">
                  <FaqsComponent
                    question={data?.question}
                    answer={data?.answer}
                  />
                </div>
              </Col>
            </Row>
          );
        })}
      </section>

      <CustomFooter />
    </div>
  );
};

export default Faqs;
