import React from "react";
import "./style.css";
import { Col, Row } from "antd";

const AboutChexComponent = () => {
  return (
    <div style={{ marginTop: "50px" }}>
      <section className="new_about_section_main">
        <Row>
          <Col xl={24} md={24} sm={24}>
            <div className="about_Content_section_wrapper">
              <h2 className="about-chex-left-title_main_new">
                About Chex.AI
                {/* <span className="about-chex-left-title-span_main_new">.AI</span> */}
              </h2>
              <p
                style={{
                  maxWidth: "1260px",
                  textAlign: "center",
                  marginTop: "20px",
                  fontSize: "24px",
                }}
              >
                Chex.AI revolutionizes vehicle safety inspection with its
                cutting-edge platform, employing advanced damage detection
                technology. Businesses benefit from real-time insights, spotting
                any new vehicle damage instantly. With a user-friendly app and
                comprehensive dashboard, Chex.AI streamlines inspection reviews
                and provides detailed insights, enhancing operational efficiency
                and safety standards.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={24} md={24} sm={24}>
            <div className="Chex_video_wrapper">
              <video
                poster="https://res.cloudinary.com/dgjordf6e/image/upload/v1666085419/banner_image_poster_a2pplr.png"
                type="video/mp4"
                playsInline
                controls={true}
                className="chex-demo-video_custom"
              >
                <source
                  src="https://res.cloudinary.com/dgjordf6e/video/upload/v1695888050/CHex_ai_je0una.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
};
export default AboutChexComponent;
