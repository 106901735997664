import React from "react";
import "./style.css"; // Keep original styles for backward compatibility
import styles from "./CompaniesSection.module.css"; // Import CSS modules
import { useHistory, useLocation } from "react-router-dom";

// Placeholder url image URL

const CompaniesSection = ({
  checkEmpty,
  setCheckEmpty,
  inspectionState,
  reInspection,
  stateCompanies,
  filteredCompanies,
  filteredCompaniesSelected,
  handleInspectionCheck,
  handleCreateInspection,
  handleEditInspection,
  handleAddNewCompany,
  loadingSelect,
  loadingcompny,
  inspectionChecked,
  numberEmpty,
  setNumberEmpty,
  stateEmpty,
  setStateEmpty,
  licensePlateNumber,
  TiTick,
  ClipLoader,
  handleNextStep,
  currentStep,
  inspectionId, // Add inspectionId prop
  vehicleId,
}) => {
  const history = useHistory();
  const location = useLocation();

  // Function to handle back button
  const handleBack = () => {
    const params = new URLSearchParams(location.search);
    params.delete("next");

    // // Keep other important parameters
    // const licensePlate = params.get("licensePlate");
    // const reInspectionPlate = params.get("reInspectionPlate");
    // const inspectionState = params.get("inspectionState");
    // const inspectionId = params.get("inspectionId"); // Make sure to preserve inspectionId
    // const vehicleId = params.get("vehicleId"); // Make sure to preserve vehicleId

    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return (
    <div>
      <div>
        {/* Back button */}
        {/* <div className={styles.backButtonContainer}>
          <button className={styles.backButton} onClick={handleBack}>
            &larr; Back
          </button>
        </div> */}

        {checkEmpty === true ? (
          <div className={styles.selectCompanyHeadingError}>
            Select Company (s)
          </div>
        ) : (
          <div className={styles.selectCompanyHeading}>Select Company (s)</div>
        )}
        <div className={styles.selectCompanyChecksContainer}>
          {inspectionState === "" && reInspection === "false" ? (
            <div className={styles.loaderContainer}>
              Select Inspection State First
            </div>
          ) : reInspection === "edit" ? (
            <>
              <div
                className={styles.companiesGrid}
                style={{ marginBottom: 35 }}
              >
                {stateCompanies?.map((company) => {
                  return (
                    company?.displayOnFront === true && (
                      <div
                        className={`${styles.companyBox} ${
                          inspectionChecked.includes(company?.id)
                            ? styles.companyBoxSelected
                            : ""
                        }`}
                        onClick={() => {
                          handleInspectionCheck(company?.id);
                          setCheckEmpty(false);
                        }}
                        key={company?.id}
                      >
                        <div className={styles.tickContainer}>
                          {inspectionChecked.includes(company?.id) ? (
                            <TiTick
                              className={styles.tickBackgroundColor}
                              color="#FF7C00"
                              size={22}
                            />
                          ) : (
                            <div className={styles.tickBackgroundColor}></div>
                          )}
                        </div>
                        <img
                          src={company?.url}
                          alt={`${company?.name} url`}
                          className={styles.companyLogo}
                        />
                        <div className={styles.companyNameText}>
                          {company?.name}
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </>
          ) : reInspection === "addCompany" ? (
            <>
              <div
                className={styles.companiesGrid}
                style={{ marginBottom: 35 }}
              >
                {filteredCompanies()?.map((company) => (
                  <div
                    className={`${styles.companyBox} ${
                      inspectionChecked.includes(company?.id)
                        ? styles.companyBoxSelected
                        : ""
                    }`}
                    onClick={() => {
                      handleInspectionCheck(company?.id);
                      setCheckEmpty(false);
                    }}
                    key={company?.id}
                  >
                    <div className={styles.tickContainer}>
                      {inspectionChecked.includes(company?.id) ? (
                        <TiTick
                          className={styles.tickBackgroundColor}
                          color="#FF7C00"
                          size={22}
                        />
                      ) : (
                        <div className={styles.tickBackgroundColor}></div>
                      )}
                    </div>
                    <img
                      src={company?.url}
                      alt={`${company?.name} url`}
                      className={styles.companyLogo}
                    />
                    <div className={styles.companyNameText}>
                      {company?.name}
                    </div>
                  </div>
                ))}

                {filteredCompaniesSelected()?.map((company) => (
                  <div
                    className={`${styles.companyBox} ${styles.companyDisabled}`}
                    key={company?.id}
                  >
                    <div className={styles.tickContainer}>
                      <TiTick
                        className={styles.tickBackgroundColor}
                        color="gray"
                        size={22}
                      />
                    </div>
                    <img
                      src={company?.url}
                      alt={`${company?.name} url`}
                      className={styles.companyLogo}
                    />
                    <div className={styles.companyNameTextDisabled}>
                      {company?.name}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : loadingcompny ? (
            <div className={styles.loaderContainer}>
              <ClipLoader color={"#246DB5"} size={20} />
            </div>
          ) : (
            <div className={styles.companiesGrid} style={{ marginBottom: 35 }}>
              {stateCompanies?.map(
                (company) =>
                  company?.displayOnFront === true && (
                    <div
                      className={`${styles.companyBox} ${
                        inspectionChecked.includes(company?.id)
                          ? styles.companyBoxSelected
                          : ""
                      }`}
                      onClick={() => {
                        handleInspectionCheck(company?.id);
                        setCheckEmpty(false);
                      }}
                      key={company?.id}
                    >
                      <div className={styles.tickContainer}>
                        {inspectionChecked.includes(company?.id) ? (
                          <TiTick
                            className={styles.tickBackgroundColor}
                            color="#FF7C00"
                            size={22}
                          />
                        ) : (
                          <div className={styles.tickBackgroundColor}></div>
                        )}
                      </div>
                      <img
                        src={company?.url}
                        alt={`${company?.name} url`}
                        className={styles.companyLogo}
                      />
                      <div className={styles.companyNameText}>
                        {company?.name}
                      </div>
                    </div>
                  )
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.inpectionInstructionBtnContainer}>
        {/* Re-inspection condition */}
        {reInspection === "true" ? (
          <>
            {inspectionChecked.length !== 0 && checkEmpty === false ? (
              <div className={styles.inspectionBtnAlign}>
                <div
                  className={styles.inspectionBtnContainer}
                  onClick={() => handleCreateInspection(reInspection)}
                >
                  {loadingSelect ? (
                    <div className={styles.loaderSpinner}>
                      <ClipLoader color={"#246DB5"} size={20} />
                    </div>
                  ) : (
                    <div className={styles.selectText}>Select</div>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.inspectionBtnAlign}>
                <div
                  className={styles.inspectionBtnContainer}
                  onClick={() => {
                    setCheckEmpty(true);
                  }}
                >
                  <div className={styles.selectText}>Select</div>
                </div>
              </div>
            )}
          </>
        ) : reInspection === "edit" ? (
          <>
            {inspectionChecked.length !== 0 && checkEmpty === false ? (
              <div className={styles.inspectionBtnAlign}>
                <div
                  className={styles.inspectionBtnContainer}
                  onClick={() => {
                    handleEditInspection(reInspection);
                  }}
                >
                  {loadingSelect ? (
                    <div className={styles.loaderSpinner}>
                      <ClipLoader color={"#246DB5"} size={20} />
                    </div>
                  ) : (
                    <div className={styles.selectText}>Select</div>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.inspectionBtnAlign}>
                <div
                  className={styles.inspectionBtnContainer}
                  onClick={() => {
                    setCheckEmpty(true);
                  }}
                >
                  <div className={styles.selectText}>Select</div>
                </div>
              </div>
            )}
          </>
        ) : reInspection === "addCompany" ? (
          <>
            {inspectionChecked.length !== 0 && checkEmpty === false ? (
              <div className={styles.inspectionBtnAlign}>
                <div
                  className={styles.inspectionBtnContainer}
                  onClick={() => handleAddNewCompany(reInspection)}
                >
                  {loadingSelect ? (
                    <div className={styles.loaderSpinner}>
                      <ClipLoader color={"#246DB5"} size={20} />
                    </div>
                  ) : (
                    <div className={styles.selectText}>Select</div>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.inspectionBtnAlign}>
                <div
                  className={styles.inspectionBtnContainer}
                  onClick={() => {
                    setCheckEmpty(true);
                  }}
                >
                  <div className={styles.selectText}>Select</div>
                </div>
              </div>
            )}
          </>
        ) : reInspection === "false" ? (
          <>
            {inspectionChecked.length !== 0 &&
            checkEmpty === false &&
            numberEmpty === false &&
            licensePlateNumber !== "" &&
            inspectionState !== "" &&
            stateEmpty === false ? (
              <div className={styles.inspectionBtnAlign}>
                <div
                  className={styles.inspectionBtnContainer}
                  onClick={() => handleCreateInspection(reInspection)}
                >
                  {loadingSelect ? (
                    <div className={styles.loaderSpinner}>
                      <ClipLoader color={"#246DB5"} size={20} />
                    </div>
                  ) : (
                    <div className={styles.selectText}>Next</div>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.inspectionBtnAlign}>
                <div
                  className={styles.inspectionBtnContainer}
                  onClick={() => {
                    // Set appropriate error states
                    if (inspectionChecked.length === 0) setCheckEmpty(true);
                    if (licensePlateNumber === "") setNumberEmpty(true);
                    if (inspectionState === "") setStateEmpty(true);
                  }}
                >
                  <div className={styles.selectText}>Select</div>
                </div>
              </div>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CompaniesSection;
