import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import InputMask from "react-input-mask";
import ClipLoader from "react-spinners/ClipLoader";
import styles from "./RegistrationFormComponent.module.css";

const RegistrationFormComponent = ({
  handleSubmit,
  handleCheckBox,
  handleLoginRedirect,
  handleTermsOfUse,
  isLoading,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  // Check if screen is mobile size
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    checkIfMobile();

    // Add event listener for window resize
    window.addEventListener("resize", checkIfMobile);

    // Clean up
    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  return (
    <div className={styles.formSection} id="wrapper_form_id">
      <img
        src="https://example.com/your-car-inspection-background.jpg"
        alt="Car inspection background"
        className={styles.backgroundImage}
      />
      <div className={styles.overlay}>
        <div className={styles.layout}>
          {/* Left panel with heading */}
          <div className={styles.leftPanel}>
            <h1 className={styles.heading}>
              {isMobile ? (
                "YOU CAN MANAGE & INSPECT YOUR CAR ONLINE"
              ) : (
                <>
                  YOU CAN
                  <br />
                  MANAGE &<br />
                  INSPECT
                  <br />
                  YOUR CAR
                  <br />
                  ONLINE
                </>
              )}
            </h1>
          </div>

          {/* Right panel with form */}
          <div className={styles.rightPanel}>
            <div className={styles.formGlass}>
              <h2 className={styles.formHeading}>
                <span>Sign up</span> and start
                {!isMobile && <br />}
                {isMobile && " "}
                vehicle inspection
              </h2>

              <div className={styles.formWrapperMain}>
                <Form
                  className="form"
                  autoComplete="off"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={handleSubmit}
                >
                  <div className={styles.namingSections}>
                    <Form.Item
                      name="name"
                      className={styles.formItemFirst}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your first name!",
                        },
                      ]}
                    >
                      <Input
                        className={styles.inputField}
                        placeholder="First Name"
                        autoComplete="off"
                      />
                    </Form.Item>

                    <Form.Item
                      name="last_name"
                      className={styles.formItemFirst}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your last name!",
                        },
                      ]}
                    >
                      <Input
                        className={styles.inputField}
                        placeholder="Last Name"
                        autoComplete="off"
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="email"
                    type="email"
                    className={styles.formItem}
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid email!",
                        pattern: new RegExp(
                          /^\s*(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*\s*$/
                        ),
                      },
                    ]}
                  >
                    <Input
                      className={styles.inputField}
                      placeholder="Email Address"
                      autoComplete="off"
                    />
                  </Form.Item>

                  <Form.Item
                    name="phone"
                    className={styles.formItem}
                    rules={[
                      {
                        required: true,
                        message: "Please enter phone number!",
                      },
                    ]}
                  >
                    <InputMask
                      mask="+1(999)999 9999"
                      className="form-control"
                      id="phone"
                      placeholder="Phone Number"
                      type="text"
                      name="phone"
                      autoComplete="phone"
                      required
                      disabled={false}
                    >
                      {() => (
                        <Input
                          id="phone"
                          placeholder="Phone Number"
                          type="text"
                          name="phone"
                          className={styles.inputField}
                          autoComplete="off"
                        />
                      )}
                    </InputMask>
                  </Form.Item>

                  <Form.Item
                    name="password"
                    className={styles.formItem}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your password!",
                      },
                    ]}
                  >
                    <Input
                      className={styles.inputField}
                      placeholder="Password"
                      autoComplete="off"
                      style={{ color: "white" }}
                      type="password"
                    />
                  </Form.Item>

                  <div className={styles.checkboxContainer}>
                    <div className={styles.checkboxInner}>
                      <label className={styles.checkboxLabel}>
                        <input onChange={handleCheckBox} type="checkbox" />
                        <span className={styles.checkmark}></span>
                      </label>
                      <div className={styles.termsText}>
                        By checking the box, you accept our{" "}
                        <span
                          className={styles.termsLink}
                          onClick={() => handleTermsOfUse()}
                        >
                          Terms of Use.
                        </span>
                      </div>
                    </div>
                  </div>

                  <Form.Item>
                    <Button
                      disabled={isLoading}
                      className={styles.submitButton}
                      htmlType="submit"
                    >
                      {isLoading ? (
                        <ClipLoader color={"#fff"} size={20} />
                      ) : (
                        "Register"
                      )}
                    </Button>
                  </Form.Item>

                  <div className={styles.loginLink}>
                    <div>
                      Already have an account?{" "}
                      <span
                        className={styles.loginTextLink}
                        onClick={() => handleLoginRedirect()}
                      >
                        Login.
                      </span>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationFormComponent;
