import React from "react";
import styles from "./InspectionCard.module.css";
import { useHistory } from "react-router-dom";

const InspectionCard = ({
  title = "BASIC INSPECTION",
  price = "29.99",
  description = "Single Inspection",
  subDescription = "",
  buttonText = "Apply for Inspection",
  backgroundColor = "#f5f5f5",
  priceColor = "#1976d2",
  buttonColor = "#1976d2",
  buttonHoverColor = "#1565c0",
  customeLogo = null,
  buttonlink = "#",
  padding,
}) => {
  console.log(padding);
  const Route = window.location.pathname;

  const lyftUberRoute =
    Route === "/lyft-inspection" || Route === "/uber-inspection";
  return (
    <div className={styles.container}>
      <div className={styles.card} style={{ backgroundColor }}>
        <div className={lyftUberRoute && padding ? styles.logoStyle : null}>
          {customeLogo && customeLogo}
        </div>
        <h1
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        ></h1>

        <div className={styles.priceContainer}>
          <span className={styles.dollarSign} style={{ color: priceColor }}>
            $
          </span>
          <span className={styles.amount} style={{ color: priceColor }}>
            {price}
          </span>
        </div>

        <p className={styles.description}>{description}</p>
        {subDescription && (
          <p className={styles.subDescription}>{subDescription}</p>
        )}

        <a
          className={`${styles.button}`}
          href={buttonlink}
          style={{
            backgroundColor: buttonColor,
          }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = buttonHoverColor;
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = buttonColor;
          }}
        >
          {buttonText}
        </a>
      </div>
    </div>
  );
};

export default InspectionCard;
