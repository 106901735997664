import React, { useState } from "react";
import styles from "./FaqRideShare.module.css";

const arrow = (isOpen) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="8"
    fill="none"
    viewBox="0 0 12 8"
    style={{ transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" }}
  >
    <path
      fill="#000"
      d="m6.613.612 4.947 4.787a.82.82 0 0 1 0 1.193l-.822.796a.89.89 0 0 1-1.233 0L5.998 3.995 2.492 7.388a.89.89 0 0 1-1.233 0l-.823-.796a.82.82 0 0 1 0-1.193L5.383.612a.885.885 0 0 1 1.23 0"
    ></path>
  </svg>
);

// Individual FAQ item component
const FAQItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div className={styles.faqItem}>
      <button
        className={styles.faqQuestion}
        onClick={onClick}
        aria-expanded={isOpen}
      >
        <span>{question}</span>
        <span className={styles.arrow}>{arrow(isOpen)}</span>
      </button>
      <div className={`${styles.faqAnswer} ${isOpen ? styles.open : ""}`}>
        <div className={styles.faqContent}>{answer}</div>
      </div>
      {/* <div className={styles.divider} /> */}
    </div>
  );
};

// Main FAQ component
const FaqRideShare = () => {
  const [openIndex, setOpenIndex] = useState(0);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "I need car inspection for Uber/Lyft?",
      answer: (
        <p>
          You can use our online platform for your Uber and Lyft inspection.
          Please register your account at{" "}
          <a href="https://www.chex.ai/register">
            https://www.chex.ai/register
          </a>{" "}
          and follow the instructions to upload photos and videos from your
          phone. Once you submit, we'll prepare the certificate for you to
          upload to your Uber/Lyft account.
        </p>
      ),
    },
    {
      question: "How much does an inspection cost through Chex.AI?",
      answer: (
        <p>
          We charge $29.99 for a single inspection. If you want to bundle it
          with multiple inspections it will be additional $17.99 for each. You
          can find details on service fee on our website.
        </p>
      ),
    },
    {
      question: "Is your inspection accepted by ridesharing companies?",
      answer: (
        <p>
          Yes, we are certified to conduct vehicle inspections for rideshare and
          car-sharing companies and have completed thousands of inspections.
        </p>
      ),
    },
    {
      question: "I don't see my state in the inspection states list.",
      answer: (
        <p>
          Unfortunately, we are not authorized to conduct inspections in all 50
          states. States missing from the list are the ones where we don’t
          operate. We update the list regularly and may add states as we get
          approvals.
        </p>
      ),
    },
    {
      question: "Do I need to set up an appointment to get my car inspected?",
      answer: (
        <p>
          No, all inspections are done through your smartphone. All you need to
          do is register your account, start an inspection and upload photos and
          short video clips at your convenience, from any location of your
          choice.
        </p>
      ),
    },
    {
      question:
        "What happens after I upload all the inspection items (Photos and Videos)?",
      answer: (
        <p>
          Once you upload all the inspection items, our inspectors review those
          and our system automatically generates certificates for you.
        </p>
      ),
    },
    {
      question: "How do I get my certificate?",
      answer: (
        <p>
          You will receive an email confirming that the certificate is ready.
          All certificates are stored in your Chex.AI account and can be
          accessed in the “Certificates” section of the homepage.
        </p>
      ),
    },
    {
      question: "What should I do after I get my certificate?",
      answer: (
        <p>
          You will have to either download the PDF or take a screenshot of the
          certificate and upload it into your rideshare company account. We also
          send a copy of the certificate in an email directly to some of our
          ride sharing partners.
        </p>
      ),
    },
  ];

  return (
    <div className={styles.faqContainer}>
      <h1 className={styles.faqTitle}>Frequently asked questions</h1>
      <div className={styles.faqList}>
        {faqData.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            isOpen={openIndex === index}
            onClick={() => toggleFAQ(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default FaqRideShare;
