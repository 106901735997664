// /* eslint-disable */
// import React, { useEffect, useState } from "react";
// import { Dropdown, Menu } from "antd";
// import { Drawer, Button, Grid } from "antd";
// // import chexlogo from '../../Assets/chexlogo.png';
// import { Link } from "react-router-dom";

// import "./style.css";

// const { useBreakpoint } = Grid;
// const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;

// const handleLogo = () => {
//   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
// };

// const menu = (
//   <Menu className="dropDown_newnav">
//     <Menu.Item key="0" className="">
//       <span className="linkNavbar_wrap">
//         <Link to="/ride-share" style={{ color: "white" }} onClick={handleLogo}>
//           Ride share
//         </Link>
//       </span>
//     </Menu.Item>
//     {/* <Menu.Item key="1" className='menu-items_newnav'>
//            <span className="linkNavbar_wrap">
//                             <Link to="/micro-mobility" style={{color:'white'}} onClick={handleLogo}>
//                                    Micromobility
//                             </Link>
//                                 </span>
//         </Menu.Item> */}
//     <Menu.Item key="1" className="menu-items_newnav">
//       <span className="linkNavbar_wrap">
//         <Link
//           to="/fleet-inspection"
//           style={{ color: "white" }}
//           onClick={handleLogo}
//         >
//           Fleet & DSP
//         </Link>
//       </span>
//     </Menu.Item>
//   </Menu>
// );

// const CustomNavbar = (props) => {
//   const [visible, setVisible] = useState(false);
//   const { md } = useBreakpoint();
//   const [scroll, setScroll] = useState(false);
//   useEffect(() => {
//     window.addEventListener("scroll", () => {
//       setScroll(window.scrollY > props.scrollValue);
//     });
//   }, []);

//   const showDrawer = () => {
//     setVisible(true);
//   };

//   const onClose = () => {
//     setVisible(false);
//   };

//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//       }}
//     >
//       <nav className={`${scroll ? "ScrollBackColor" : "null"} , menuBar`}>
//         <div className="logo_home">
//           <Link onClick={handleLogo}>
//             <img src="https://res.cloudinary.com/dgjordf6e/image/upload/v1665470829/Group_354logo_b0hqj7_woxzsi.png" />
//           </Link>
//         </div>
//         <div className="menuCon">
//           <div className="leftMenu">
//             <Menu
//               className="menuNavbarMain"
//               mode={md ? "horizontal" : "inline"}
//             >
//               <li className="my-menu-item">
//                 <span className="linkNavbar_wrap">
//                   <Link to="/" onClick={handleLogo}>
//                     Home
//                   </Link>
//                 </span>
//               </li>

//               <Dropdown overlay={menu} className="my-menu-item">
//                 <a
//                   href="#/"
//                   className="ant-dropdown-link"
//                   onClick={(e) => e.preventDefault()}
//                 >
//                   Services
//                 </a>
//               </Dropdown>

//               {/* <li className="my-menu-item">
//                             <span className="linkNavbar_wrap">
//                             <Link to="/faqs" onClick={handleLogo}>
//                                   FAQ
//                             </Link>
//                                 </span>

//                         </li> */}
//               <li className="my-menu-item">
//                 <span className="linkNavbar_wrap">
//                   <Link to="/blogPage" onClick={handleLogo}>
//                     Blog
//                   </Link>
//                 </span>
//               </li>

//               {/* <li className="my-menu-item">
//                             <span className="linkNavbar_wrap">
//                             <Link to="/blogPage" onClick={handleLogo}>
//                                   Blog
//                             </Link>
//                                 </span>

//                         </li> */}
//               {/* {window?.location?.pathname === "/contactPage" || window?.location?.pathname === "/fleet-inspection"? null :
//                             window?.location?.pathname === "/" ?
//                                 <li className="my-menu-item">
//                                     <a href="/ride-share#pricingSectionComplete">
//                             <span className="linkNavbar_wrap_pricing">

//                                 Pricing

//                             </span>
//                                     </a>

//                                 </li>
//                                 :
//                             window?.location?.pathname === "/LandingPage" ?
//                              <li className="my-menu-item">
//                                 <a href="/ride-share#pricingSectionComplete">
//                             <span className="linkNavbar_wrap_pricing">

//                                 Pricing

//                             </span>
//                                 </a>

//                             </li>
//                                 :
//                                 window?.location?.pathname === "/micro-mobility" ?
//                                     <li className="my-menu-item">
//                                         <a href="/ride-share#pricingSectionComplete">
//                             <span className="linkNavbar_wrap_pricing">

//                                 Pricing

//                             </span>
//                                         </a>

//                                     </li>
//                                      :
//                                      window?.location?.pathname === "/blogPage" ?
//                                          <li className="my-menu-item">
//                                              <a href="/ride-share#pricingSectionComplete">
//                                  <span className="linkNavbar_wrap_pricing">

//                                      Pricing

//                                  </span>
//                                              </a>

//                                          </li> :
//                             // window?.location?.pathname === `/blog-detail/:id`?
//                             window?.location?.pathname.match(/^\/blog-detail\/(\d+)$/)?

//                             <li className="my-menu-item">
//                                 <a href="/ride-share#pricingSectionComplete" >
//                     <span className="linkNavbar_wrap_pricing"  >

//                         Pricing

//                     </span>
//                                 </a>

//                             </li>

//                             :

//                             <li className="my-menu-item">
//                             <a href="/ride-share#pricingSectionComplete">
//                             <span className="linkNavbar_wrap_pricing">

//                             Pricing

//                             </span>
//                             </a>

//                             </li>
//                         } */}

//               <li className="my-menu-item">
//                 <span className="linkNavbar_wrap">
//                   <Link to="/contactPage" onClick={handleLogo}>
//                     Contact Us
//                   </Link>
//                 </span>
//               </li>

//               <li className="my-menu-item">
//                 <span
//                   className="linkNavbar_wrap"
//                   onClick={() =>
//                     window.open(
//                       `${process.env.REACT_APP_DASHBOARD_URL}`,
//                       "_blank"
//                     )
//                   }
//                 >
//                   <Link to="/LandingPage">Dashboard</Link>
//                 </span>
//               </li>
//               <li className="my-menu-item">
//                 <span className="linkNavbar_wrap">
//                   <Link to="/login">Login</Link>
//                 </span>
//               </li>
//               <li className="my-menu-item">
//                 <span className="linkNavbar_wrap">
//                   <Link to="/request-a-demo" onClick={handleLogo}>
//                     <span className="req_a_demo">Request A Demo</span>
//                   </Link>
//                 </span>
//               </li>

//               {/*<Menu.Item key="fd">*/}
//               {/*    <span className="linkNavbar_wrap">*/}
//               {/*    <Link to="/LandingPage" >*/}
//               {/*          Home*/}
//               {/*    </Link>*/}
//               {/*        </span>*/}
//               {/*</Menu.Item>*/}

//               {/*<SubMenu key="sub1" title={<span>Services</span>} >*/}
//               {/*    <MenuItemGroup title="" className="dropDownMenuWrap">*/}
//               {/*        <Menu.Item className="linkNavbar_wrap_menu">*/}
//               {/*            <span className="linkNavbar_wrap">*/}
//               {/*    <Link to="/uber-inspection" style={{color:'white'}}>*/}
//               {/*            Uber Inspections*/}
//               {/*    </Link>*/}
//               {/*        </span>*/}

//               {/*        </Menu.Item>*/}
//               {/*        <Menu.Item key="setting:2">*/}
//               {/*             <span className="linkNavbar_wrap">*/}
//               {/*    <Link to="/lyft-inspection" style={{color:'white'}}>*/}
//               {/*            Lyft Inspections*/}
//               {/*    </Link>*/}
//               {/*        </span>*/}
//               {/*        </Menu.Item>*/}
//               {/*    </MenuItemGroup>*/}

//               {/*</SubMenu>*/}
//               {/*<Menu.Item key="alipay">*/}
//               {/*    <span className="linkNavbar_wrap">*/}
//               {/*    <Link to="/contactUs" >*/}
//               {/*    Contact Us*/}
//               {/*    </Link>*/}
//               {/*    </span>*/}

//               {/*</Menu.Item>*/}
//               {/*<Menu.Item >*/}
//               {/*    <a href="#pricingSectionComplete">*/}
//               {/*    <span className="linkNavbar_wrap_pricing">*/}

//               {/*    /!*<Link to={props.idname} >*!/*/}
//               {/*    /!* <Link to="#pricingSectionComplete" > *!/*/}
//               {/*    Pricing*/}
//               {/*    /!* </Link> *!/*/}

//               {/*    /!*</Link>*!/*/}

//               {/*    </span>*/}
//               {/*    </a>*/}
//               {/*</Menu.Item>*/}
//               {/*<Menu.Item >*/}
//               {/*    <span className="linkNavbar_wrap" onClick={()=> window.open(`${process.env.REACT_APP_DASHBOARD_URL}`, "_blank")} >*/}
//               {/*    /!* <div > *!/*/}
//               {/*    <Link>*/}
//               {/*    Dashboard*/}
//               {/*    </Link>*/}
//               {/*    /!* </div> *!/*/}
//               {/*    </span>*/}

//               {/*</Menu.Item>*/}

//               {/*<Menu.Item >*/}
//               {/*    <span className="linkNavbar_wrap">*/}
//               {/*    <Link to="/login" >*/}
//               {/*    Login*/}
//               {/*    </Link>*/}
//               {/*    </span>*/}

//               {/*</Menu.Item>*/}
//             </Menu>
//           </div>
//           <Button className="barsMenu" type="primary" onClick={showDrawer}>
//             <span className="barsBtn"></span>
//           </Button>
//           <Drawer
//             placement="right"
//             closable={false}
//             onClose={onClose}
//             visible={visible}
//             className="drawerMenu"
//           >
//             <Menu
//               style={{
//                 color: "white",
//                 backgroundColor: "#1B223B",
//                 height: "100%",
//               }}
//               mode={md ? "horizontal" : "inline"}
//             >
//               <Menu.Item key="home">
//                 <span className="linkNavbar_wrap">
//                   <Link
//                     to="/LandingPage"
//                     style={{ color: "#fff" }}
//                     onClick={handleLogo}
//                   >
//                     Home
//                   </Link>
//                 </span>
//               </Menu.Item>
//               <SubMenu title="Services">
//                 <MenuItemGroup
//                   style={{ backgroundColor: "#1B223B", borderBottom: "none" }}
//                 >
//                   <Menu.Item style={{ color: "#fff" }} key="setting:1">
//                     <span className="linkNavbar_wrap">
//                       <Link
//                         to="/ride-share"
//                         style={{ color: "#fff" }}
//                         onClick={handleLogo}
//                       >
//                         Ride Share
//                       </Link>
//                     </span>
//                   </Menu.Item>
//                   {/* <Menu.Item style={{color: '#fff'}} key="setting:2">
//                                       <span className="linkNavbar_wrap">
//                             <Link to="/micro-mobility"  style={{color: '#fff'}} onClick={handleLogo}>
//                          Micromobility
//                             </Link>
//                             </span>
//                                 </Menu.Item> */}
//                   <Menu.Item style={{ color: "#fff" }} key="setting:2">
//                     <span className="linkNavbar_wrap">
//                       <Link
//                         to="/fleet-inspection"
//                         style={{ color: "#fff" }}
//                         onClick={handleLogo}
//                       >
//                         Fleet & DSP
//                       </Link>
//                     </span>
//                   </Menu.Item>
//                 </MenuItemGroup>
//               </SubMenu>
//               {/* <Menu.Item key="contact">
//                             <span className="linkNavbar_wrap">
//                             <Link to="/faqs" style={{color: '#fff'}} onClick={handleLogo}>
//                             FAQ
//                             </Link>
//                             </span>

//                         </Menu.Item> */}
//               <Menu.Item key="contact">
//                 <span className="linkNavbar_wrap">
//                   <Link
//                     to="/blogPage"
//                     style={{ color: "#fff" }}
//                     onClick={handleLogo}
//                   >
//                     Blog
//                   </Link>
//                 </span>
//               </Menu.Item>
//               {/* {window?.location?.pathname === "/contactPage" ||window?.location?.pathname === "/fleet-inspection" ? null :
//                             window?.location?.pathname === "/" ?
//                                 <Menu.Item key="pricing">
//                                     <a href="/ride-share#pricingSectionComplete" className='styling_ankr_pricing'>
//                                     <span className="linkNavbar_wrap_pricing">

//                                     Pricing

//                                     </span>
//                                     </a>

//                                 </Menu.Item>
//                                 :
//                             window?.location?.pathname === "/LandingPage" ?
//                             <Menu.Item key="pricing">
//                                 <a href="/ride-share#pricingSectionComplete" className='styling_ankr_pricing'>
//                                     <span className="linkNavbar_wrap_pricing">

//                                     Pricing

//                                     </span>
//                                 </a>

//                             </Menu.Item>
//                                 :
//                                 window?.location?.pathname === "/micro-mobility" ?
//                                     <Menu.Item key="pricing">
//                                         <a href="/ride-share#pricingSectionComplete" className='styling_ankr_pricing'>
//                                     <span className="linkNavbar_wrap_pricing">

//                                     Pricing

//                                     </span>
//                                         </a>

//                                     </Menu.Item>
//                                     :
//                                     <Menu.Item key="pricing">
//                                         <a href="/ride-share#pricingSectionComplete" className='styling_ankr_pricing'>
//                                     <span className="linkNavbar_wrap_pricing">

//                                     Pricing

//                                     </span>
//                                         </a>

//                                     </Menu.Item>

//                         } */}

//               <Menu.Item key="contact">
//                 <span className="linkNavbar_wrap">
//                   <Link
//                     to="/contactPage"
//                     style={{ color: "#fff" }}
//                     onClick={handleLogo}
//                   >
//                     Contact Us
//                   </Link>
//                 </span>
//               </Menu.Item>
//               <Menu.Item key="dashboard">
//                 <span
//                   className="linkNavbar_wrap"
//                   onClick={() =>
//                     window.open(
//                       `${process.env.REACT_APP_DASHBOARD_URL}`,
//                       "_blank"
//                     )
//                   }
//                 >
//                   <Link to="/login" style={{ color: "#fff" }}>
//                     Dashboard
//                   </Link>
//                 </span>
//               </Menu.Item>
//               <Menu.Item key="login">
//                 <span className="linkNavbar_wrap">
//                   <Link to="/login" style={{ color: "#fff" }}>
//                     Login
//                   </Link>
//                 </span>
//               </Menu.Item>
//               <Menu.Item key="request-a-demo">
//                 <span className="linkNavbar_wrap">
//                   <Link
//                     onClick={handleLogo}
//                     to="/request-a-demo"
//                     style={{ color: "#fff" }}
//                   >
//                     Request a Demo
//                   </Link>
//                 </span>
//               </Menu.Item>
//             </Menu>
//           </Drawer>
//           {/* </div> */}
//         </div>
//       </nav>
//     </div>
//   );
// };
// export default CustomNavbar;

/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";
import { Drawer, Button, Grid } from "antd";
import { Link } from "react-router-dom";

import "./style.css";

const { useBreakpoint } = Grid;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const handleLogo = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

const menu = (
  <Menu className="dropDown_newnav">
    <Menu.Item key="0" className="">
      <span className="linkNavbar_wrap">
        <Link to="/ride-share" style={{ color: "white" }} onClick={handleLogo}>
          Ride share
        </Link>
      </span>
    </Menu.Item>
    <Menu.Item key="1" className="menu-items_newnav">
      <span className="linkNavbar_wrap">
        <Link
          to="/fleet-inspection"
          style={{ color: "white" }}
          onClick={handleLogo}
        >
          Fleet & DSP
        </Link>
      </span>
    </Menu.Item>
  </Menu>
);

const CustomNavbar = (props) => {
  const [visible, setVisible] = useState(false);
  const { md } = useBreakpoint();
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > props.scrollValue);
    });
  }, []);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <nav className={`${scroll ? "ScrollBackColor" : "null"} , menuBar `}>
        <div className="logo_home">
          <Link onClick={handleLogo}>
            <img src="https://res.cloudinary.com/dgjordf6e/image/upload/v1665470829/Group_354logo_b0hqj7_woxzsi.png" />
          </Link>
        </div>
        <div className="menuCon">
          <div className="leftMenu">
            <Menu
              className="menuNavbarMain"
              mode={md ? "horizontal" : "inline"}
            >
              <li className="my-menu-item">
                <span className="linkNavbar_wrap">
                  <Link to="/" onClick={handleLogo}>
                    Home
                  </Link>
                </span>
              </li>

              <Dropdown overlay={menu} className="my-menu-item">
                <a
                  href="#/"
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Services
                </a>
              </Dropdown>

              <li className="my-menu-item">
                <span className="linkNavbar_wrap">
                  <Link to="/blogPage" onClick={handleLogo}>
                    Blog
                  </Link>
                </span>
              </li>
              {/* 
              <li className="my-menu-item">
                <span className="linkNavbar_wrap">
                  <Link to="/faqs" onClick={handleLogo}>
                    FAQ
                  </Link>
                </span>
              </li> */}

              <li className="my-menu-item">
                <span className="linkNavbar_wrap">
                  <Link to="/contactPage" onClick={handleLogo}>
                    Contact Us
                  </Link>
                </span>
              </li>

              <li className="my-menu-item">
                <span
                  className="linkNavbar_wrap"
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_DASHBOARD_URL}`,
                      "_blank"
                    )
                  }
                >
                  <Link to="/LandingPage">Dashboard</Link>
                </span>
              </li>
              <li className="my-menu-item">
                <span className="linkNavbar_wrap">
                  <Link to="/login">Login</Link>
                </span>
              </li>
              <li className="my-menu-item">
                <span className="linkNavbar_wrap">
                  <Link to="/request-a-demo" onClick={handleLogo}>
                    <span className="req_a_demo">Request A Demo</span>
                  </Link>
                </span>
              </li>
            </Menu>
          </div>
          <Button className="barsMenu" type="primary" onClick={showDrawer}>
            <span className="barsBtn"></span>
          </Button>
          <Drawer
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
            className="drawerMenu"
          >
            <Menu
              style={{
                color: "white",
                backgroundColor: "#1B223B",
                height: "100%",
              }}
              mode={md ? "horizontal" : "inline"}
            >
              <Menu.Item key="home">
                <span className="linkNavbar_wrap">
                  <Link to="/" style={{ color: "#fff" }} onClick={handleLogo}>
                    Home
                  </Link>
                </span>
              </Menu.Item>
              <SubMenu title="Services">
                <MenuItemGroup
                  style={{ backgroundColor: "#1B223B", borderBottom: "none" }}
                >
                  <Menu.Item style={{ color: "#fff" }} key="setting:1">
                    <span className="linkNavbar_wrap">
                      <Link
                        to="/ride-share"
                        style={{ color: "#fff" }}
                        onClick={handleLogo}
                      >
                        Ride Share
                      </Link>
                    </span>
                  </Menu.Item>
                  <Menu.Item style={{ color: "#fff" }} key="setting:2">
                    <span className="linkNavbar_wrap">
                      <Link
                        to="/fleet-inspection"
                        style={{ color: "#fff" }}
                        onClick={handleLogo}
                      >
                        Fleet & DSP
                      </Link>
                    </span>
                  </Menu.Item>
                </MenuItemGroup>
              </SubMenu>
              <Menu.Item key="blog">
                <span className="linkNavbar_wrap">
                  <Link
                    to="/blogPage"
                    style={{ color: "#fff" }}
                    onClick={handleLogo}
                  >
                    Blog
                  </Link>
                </span>
              </Menu.Item>
              {/* <Menu.Item key="faqs">
                <span className="linkNavbar_wrap">
                  <Link
                    to="/faqs"
                    style={{ color: "#fff" }}
                    onClick={handleLogo}
                  >
                    FAQ
                  </Link>
                </span>
              </Menu.Item> */}
              <Menu.Item key="contact-us">
                <span className="linkNavbar_wrap">
                  <Link
                    to="/contactPage"
                    style={{ color: "#fff" }}
                    onClick={handleLogo}
                  >
                    Contact Us
                  </Link>
                </span>
              </Menu.Item>
              <Menu.Item key="dashboard">
                <span
                  className="linkNavbar_wrap"
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_DASHBOARD_URL}`,
                      "_blank"
                    )
                  }
                >
                  <Link to="/login" style={{ color: "#fff" }}>
                    Dashboard
                  </Link>
                </span>
              </Menu.Item>
              <Menu.Item key="login">
                <span className="linkNavbar_wrap">
                  <Link to="/login" style={{ color: "#fff" }}>
                    Login
                  </Link>
                </span>
              </Menu.Item>
              <Menu.Item key="request-a-demo">
                <span className="linkNavbar_wrap">
                  <Link
                    onClick={handleLogo}
                    to="/request-a-demo"
                    style={{ color: "#fff" }}
                  >
                    Request a Demo
                  </Link>
                </span>
              </Menu.Item>
            </Menu>
          </Drawer>
        </div>
      </nav>
    </div>
  );
};

export default CustomNavbar;
