import React from 'react';
import './style.css';

const HomeBadgeBoxComponent = ({ badge_image, badgeInfo, badgeHeading }) => {
    return (
      <div className="badge_box_compo_wrapper">
        <div className="icon_badge_card_box">
          <img src={badge_image} alt={badgeHeading} />
        </div>
        <h3 className="badge_heading">
          {badgeHeading}
        </h3>
        <p className="badge_info">
          {badgeInfo}
        </p>
      </div>
    );
  };
  
  export default HomeBadgeBoxComponent;