import React from "react";
import { Button } from "../../Components";

import "./style.css";
import "../../App.css";

const ThankyouScreen = ({ handleNext }) => {
  const email = JSON.parse(localStorage.getItem("currentUser_email"));

  return (
    <div className="intro-main-container">
      <div className="intro-imageBackground-main">
        {/* <img
        src="https://res.cloudinary.com/dgjordf6e/image/upload/v1742972572/image_43_wffafc.png"
        alt="thankyou"
        className="bg-car-img"
      /> */}
        <div className="intro-mainBgs">
          <div className="thank-contentMain-screen">
            <div className="thank-text-contaner">
              <div className="thankyou-contentP">Thank you for using</div>
              <div className="thankyou-chex-contentP">Chex.AI</div>
              <div className="thank-you-paragrpah">
                You may now exit our app. We will reach out to you to your email{" "}
                <span
                  style={{
                    color: "white",
                    fontWeight: 500,
                  }}
                >
                  {email || "xyz.123@gmail.com"}
                </span>{" "}
                with results shortly.
              </div>
              <div className="btn-size">
                <Button onClickButton={handleNext} title="Exit" />
              </div>

              <div className="intro-content-footer-area">
                <div className="intro-MtB5"></div>
                <div className="intro-contentDiv-button"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankyouScreen;
