import React from "react";
import { Col, Row } from "antd";
import Carousel from "react-elastic-carousel";
import "./style.css";

const BackedCompanySection = ({
  aboutBreakPoints,
  carouselRef,
  changeHanlder,
}) => {
  const Route = window.location.pathname;
  return (
    <section className="backed_company_section_main">
      <div className="backed_company_section_container">
        <Row>
          <Col xl={24} md={24} sm={24}>
            <center>
              <div className="techStar_LogoCompany">
                <p className="text_techStart_para_landing">
                  <span className="a_text_landing company_backed-new">A</span>
                  <span>
                    <img
                      src="https://res.cloudinary.com/dgjordf6e/image/upload/v1665470856/texttechstart_xurufl_e4w9rg.png"
                      alt="techstar"
                      className="img_techStar"
                    />
                  </span>{" "}
                  <span className="company_backed-new">backed company</span>
                </p>
              </div>

              <div className="companylogo_container">
                <Carousel
                  breakPoints={aboutBreakPoints}
                  pagination={false}
                  ref={carouselRef}
                  enableAutoPlay={true}
                  onChange={changeHanlder}
                  infiniteLoop
                >
                  <div className="item_company_backed_company">
                    <img
                      src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726299533/image_33_bhphep.png"
                      alt="company logo"
                    />
                  </div>

                  <div className="item_company_backed_company">
                    <img
                      src="https://res.cloudinary.com/dgjordf6e/image/upload/v1742457664/image_21-removebg-preview_hxrzwx.png"
                      alt="company logo"
                    />
                  </div>

                  {/* <div className="item_company_backed_company2">
                    <img
                      src="https://res.cloudinary.com/dgjordf6e/image/upload/v1741757868/image_67_iuavyu.png"
                      alt="company logo"
                    />
                  </div> */}

                  <div className="item_company_backed_company">
                    <img
                      src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726745076/Carepool_Logo_1_fil4mg.png"
                      alt="company logo"
                    />
                  </div>

                  <div className="item_company_backed_company">
                    <img
                      src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726299533/image_34_oijwph.png"
                      alt="company logo"
                    />
                  </div>

                  {(Route === "/uber-inspection" ||
                    Route === "/lyft-inspection") && (
                    <>
                      <div className="item_company_backed_company">
                        <img
                          src="https://res.cloudinary.com/dgjordf6e/image/upload/v1742288544/image_23_1_acixly.png"
                          alt="company logo"
                          width={117}
                          height={44}
                        />
                      </div>
                    </>
                  )}
                  {(Route === "/uber-inspection" ||
                    Route === "/lyft-inspection") && (
                    <>
                      <div className="item_company_backed_company">
                        <img
                          src="https://res.cloudinary.com/dgjordf6e/image/upload/v1742288544/image_69_hajt9n.png"
                          alt="company logo"
                        />
                      </div>
                    </>
                  )}
                  {(Route === "/uber-inspection" ||
                    Route === "/lyft-inspection") && (
                    <>
                      <div className="item_company_backed_company">
                        <img
                          src="https://res.cloudinary.com/dgjordf6e/image/upload/v1742288649/image_80_jlmrcm.png "
                          alt="company logo"
                        />
                      </div>
                    </>
                  )}
                </Carousel>
              </div>
            </center>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default BackedCompanySection;
