/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";

import { Button } from "antd";

import "../../Components/Input/style";
import "./style.css";
import ClipLoader from "react-spinners/ClipLoader";
import ReCAPTCHA from "react-google-recaptcha";
import { CustomFooter, ContactUsNavbar } from "../../Components";
import EmailNotificationModal from "../../Components/EmailNotificationModal/EmailNotificationModal";

const NewContactScreen = ({
  loading,
  formData,
  handleSubmit,
  handleChange,
  onCapchaChange,
  scrollValue,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  // useEffect(() => {
  //   setIsModalVisible(true);
  // }, []);

  const handleModal = (visible) => {
    setIsModalVisible(visible);
  };
  return (
    <div className="main_contactWrap">
      <EmailNotificationModal
        isVisible={isModalVisible}
        handleModal={handleModal}
      />
      <Row>
        <Col xl={24} md={24} xs={24}>
          <ContactUsNavbar scrollValue={scrollValue} />
          <div className="contact_headingContainer">
            <h3 className="contactText">Contact Us</h3>
          </div>
        </Col>
      </Row>
      <section className="Feedback_Section">
        <Row>
          <Col
            xl={16}
            lg={18}
            md={20}
            xs={22}
            sm={22}
            className="ant-col-lg-offset-2 ant-col-md-offset-2 ant-col-xs-offset-0"
          >
            <div className="feedbackContainerMain">
              {/* <h2 className="feedbackHeading">
                                Other Inquiries
                            </h2> */}
              <p className="paragraphfeedback">
                Please fill out the information below and we will contact you as
                soon as possible
              </p>

              <Row>
                <Col lg={24} xl={11} md={24} sm={24} xs={24}>
                  <label className="input-field-label-contact-us">
                    First name:
                    <span style={{ color: "red", paddingLeft: 6 }}>*</span>
                  </label>
                  <input
                    className="input-field-style"
                    type="text"
                    value={formData?.firstName}
                    onChange={(e) => handleChange("firstName", e.target.value)}
                  />
                </Col>
                <Col lg={0} xl={2} md={0} sm={0} xs={0}></Col>
                <Col lg={24} xl={11} md={24} sm={24} xs={24}>
                  <label className="input-field-label-contact-us">
                    Last name:
                    <span style={{ color: "red", paddingLeft: 6 }}>*</span>
                  </label>
                  <input
                    className="input-field-style"
                    type="text"
                    value={formData?.lastName}
                    onChange={(e) => handleChange("lastName", e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={24} xl={11} md={24} sm={24} xs={24}>
                  <label className="input-field-label-contact-us">
                    Work Email:
                    <span style={{ color: "red", paddingLeft: 6 }}>*</span>
                  </label>
                  <input
                    className="input-field-style"
                    type="text"
                    value={formData?.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                  />
                </Col>
                <Col lg={0} xl={2} md={0} sm={0} xs={0}></Col>
                <Col lg={0} xl={11} md={0} sm={0} xs={0}></Col>
              </Row>
              <Row>
                <Col lg={24} xl={24} md={24} sm={24} xs={24}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label className="input-field-label-contact-us">
                      How can we help?
                      <span style={{ color: "red", paddingLeft: 6 }}>*</span>
                    </label>
                    <textarea
                      className="contact-us-textArea"
                      value={formData?.message}
                      rows="7"
                      cols="50"
                      onChange={(e) => handleChange("message", e.target.value)}
                    ></textarea>
                  </div>
                </Col>
              </Row>
              <div className="recapcha_desgin">
                <ReCAPTCHA
                  sitekey="6LfODPgdAAAAAPtuwKuNGKe0muxX4ODEN84Wovth"
                  onChange={onCapchaChange}
                  size="normal"
                />
              </div>
              <div>
                {loading ? (
                  <Button
                    disabled={loading ? true : false}
                    onClick={() => handleSubmit()}
                    className="button-wrapper_contactus-loading"
                  >
                    {<ClipLoader color={"black"} size={20} />}
                  </Button>
                ) : (
                  <Button
                    disabled={loading ? true : false}
                    onClick={() => handleSubmit()}
                    className="button-wrapper_contactus"
                  >
                    {"Submit"}
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </section>

      <CustomFooter />
    </div>
  );
};

export default NewContactScreen;
