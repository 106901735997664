import React from "react";
import "./style.css";
import { Col, Row } from "antd";
import Carousel from "react-elastic-carousel";
import { ReviewCard } from "../index";

const CustomReviewsComponent = ({ breakPoints }) => {
  const reviews = [
    {
      reviewText:
        "The app was easy to follow, the pictures showing what was required of me to take made is simple. Upload was fast. They responded quickly and had the inspection back within a half hour! Thank you. Well worth the money.",
      reviewerName: "Mousa Naseer",
      ratingStar: 5,
    },
    {
      reviewText:
        "Chex.ai was really easy to use, better than going to the mechanic!",
      reviewerName: "Andressa Amorim",
      ratingStar: 5,
    },

    {
      reviewText:
        "Quick and efficient! Great price and easy to upload all photos and videos required. Will definitely recommend and use it next year!",
      reviewerName: "Mubarak Behi",
      ratingStar: 5,
    },
    {
      reviewText:
        "This is an awesome service. They have made it so easy that a child could do it. I’ve been doing Rideshare for five years now I have tried other services and this is by far the best! Easiest to complete and lowest price that I’ve seen out there.",
      reviewerName: "Ali Alshammari",
      ratingStar: 5,
    },
    {
      reviewText:
        "Easy, app based Turo vehicle inspection. I had an issue and had to call support. The phone was picked up immediately by a person. Excellent service. I would definitely use Chex.AI again!",
      reviewerName: "Sal Villa",
      ratingStar: 5,
    },
  ];

  return (
    <div className="card-component">
      <section className="main_section_review_custom">
        <Row>
          <Col xl={24} md={24} xs={24}>
            <h2 className="headingCustomerReviews_custom">
              <span>Feedback from our Verified Clients</span>
            </h2>
            <p className="para_heading_sub">
              We are happy when our customers are too.
            </p>
          </Col>
        </Row>

        <Row>
          <Col xl={24} md={24} xs={24}>
            <div className="review_container_main_new">
              <Carousel
                breakPoints={breakPoints}
                pagination={false}
                showArrows={true}
              >
                {reviews.map((review, index) => (
                  <div className="review-card-items" key={index}>
                    <ReviewCard
                      reviewText={review.reviewText}
                      reviewerName={review.reviewerName}
                      ratingStar={review.ratingStar}
                      bgColor={index % 2 === 0 ? "#ffffff" : "#ffffff"}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default CustomReviewsComponent;
