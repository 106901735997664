import React from "react";
import "./style.css";
import LandingBannerComponent from "../../Components/LandingBannerComponent";
import { CustomFooter, CustomReviewsComponent, BackedCompanySection } from "../../Components";
import { Col, Row } from "antd";
import Carousel from "react-elastic-carousel";
import ManageSection from "./sections/ManageSection";
import { OurAiBasedSection } from "./sections/OurAiBasedSection";
import HowItWorkSection from "./sections/HowItWorkSection";
import { HowCanWeHelpSection } from "./sections/HowWeCanHelpSection";
import { OurCommpunitySection } from "./sections/OurCommunitySection";
import { OurSolutionSection } from "./sections/OurSolutionSection.js";
import AboutChexComponent from "./sections/AboutChexSection/index.js";
import { BenifitsSection } from "./sections/BenefitsSection/index.js";
import { KeyDiffSection } from "./sections/KeyDiffSection.js/index.js";

const ChexLandingScreen = ({
  changeHanlder,
  aboutBreakPoints,
  carouselRef,
  breakPoints,
  handleNextEnd,
}) => {
  return (
    <>
      <div className="landing_screen_main_wrapper">
        <LandingBannerComponent
          HomeLandingText={
            <div>
              {" "}
              <h3>
                {" "}
                <span className="darkGray">Safety </span> Inspection, Compliance{" "}
                <span className="darkGray"> And </span> maintenance{" "}
                <span className="darkGray"> Platform For The </span>{" "}
                <span className="darkBlue"> Mobility Industry </span>
              </h3>
            </div>
          }
          BannerImage={
            "https://res.cloudinary.com/dgjordf6e/image/upload/v1665470850/Group_495_whfm6k_vdrqjg.png"
          }
          redirectionPage={"/request-a-demo"}
        />
        
        <BackedCompanySection 
          aboutBreakPoints={aboutBreakPoints}
          carouselRef={carouselRef}
          changeHanlder={changeHanlder}
        />

        <OurAiBasedSection />
        <ManageSection />
        <HowItWorkSection />
        <BenifitsSection />
        <KeyDiffSection />
        <OurCommpunitySection />
        <OurSolutionSection />
        <HowCanWeHelpSection />

        <AboutChexComponent />

        <div>
          <CustomReviewsComponent breakPoints={breakPoints} />
        </div>

        <CustomFooter />
      </div>
    </>
  );
};
export default ChexLandingScreen;
